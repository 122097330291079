import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { RootState, AppDispatch } from "../app/store";
import { TokenInfo } from "../types";
import { getTokensOwned } from "../services/queries";
export interface MainState {
  account: string; //address of user
  signature: string; //signature of user
  LottoMint1: TokenInfo[]; //array of tokens owned by user
  G1Tokens: TokenInfo[];
  G2Tokens: TokenInfo[];
  G3Tokens: TokenInfo[];
  G4Tokens: TokenInfo[];
  counter: number;
  nonce: string | number;
  autoCamera: boolean;
}

const initialState: MainState = {
  account: "",
  signature: "",
  LottoMint1: [],
  G1Tokens: [],
  G2Tokens: [],
  G3Tokens: [],
  G4Tokens: [],
  counter: 0,
  nonce: "",
  autoCamera: true,
};

export interface TokenFamily {
  tokenFamily: "G1" | "G2" | "G3" | "G4" | "LottoMint1";
}

export const updateTokenInfo = createAsyncThunk<
  [TokenInfo[] | undefined, string],
  string,
  { state: RootState }
>("main/updateTokenInfo", async (tokenFamily, { getState }) => {
  const { main } = getState() as { main: MainState };
  let address = main.account;
  let data = await getTokensOwned(address, tokenFamily);
  return [data, tokenFamily];
});

export const mainSlice = createSlice({
  name: "main",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    setAutocamera: (state, account) => {
      state.autoCamera = account.payload;
    },
    setAccount: (state, account) => {
      state.account = account.payload;
    },
    setSignature: (state, sig) => {
      state.signature = sig.payload;
    },
    setLottoMint1Tokens: (state, tokens) => {
      state.LottoMint1 = tokens.payload;
    },
    setG1Tokens: (state, tokens) => {
      state.G1Tokens = tokens.payload;
    },
    setG2Tokens: (state, tokens) => {
      state.G2Tokens = tokens.payload;
    },
    setG3Tokens: (state, tokens) => {
      state.G3Tokens = tokens.payload;
    },
    setG4Tokens: (state, tokens) => {
      state.G4Tokens = tokens.payload;
    },
    setNonce: (state, nonce) => {
      state.nonce = nonce.payload;
    },
    incCounter: (state) => {
      state.counter += 1;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(updateTokenInfo.fulfilled, (state, action) => {
      if (action.payload[1] === "LottoMint1") {
        state.LottoMint1 = action.payload[0]
          ? action.payload[0]
          : state.LottoMint1;
      }
      if (action.payload[1] === "G1") {
        state.G1Tokens = action.payload[0] ? action.payload[0] : state.G1Tokens;
      }
      if (action.payload[1] === "G2") {
        state.G2Tokens = action.payload[0] ? action.payload[0] : state.G2Tokens;
      }
      if (action.payload[1] === "G3") {
        state.G3Tokens = action.payload[0] ? action.payload[0] : state.G3Tokens;
      }
      if (action.payload[1] === "G4") {
        state.G4Tokens = action.payload[0] ? action.payload[0] : state.G4Tokens;
      }
    });
  },
});

export const {
  setAccount,
  setSignature,
  setLottoMint1Tokens,
  setG1Tokens,
  setG2Tokens,
  setG3Tokens,
  setG4Tokens,
  setNonce,
  incCounter,
  setAutocamera,
} = mainSlice.actions;

export const selectAutocamera = (state: RootState) => state.main.autoCamera;
export const selectAccount = (state: RootState) => state.main.account;
export const selectSignature = (state: RootState) => state.main.signature;
export const selectLottoMint1Tokens = (state: RootState) =>
  state.main.LottoMint1;
export const selectG1Tokens = (state: RootState) => state.main.G1Tokens;
export const selectG2Tokens = (state: RootState) => state.main.G2Tokens;
export const selectG3Tokens = (state: RootState) => state.main.G3Tokens;
export const selectG4Tokens = (state: RootState) => state.main.G4Tokens;
export const selectAllTokens = (state: RootState) => [
  ...state.main.G1Tokens,
  ...state.main.G2Tokens,
  ...state.main.G3Tokens,
  ...state.main.G4Tokens,
  ...state.main.LottoMint1,
];
export const selectNonce = (state: RootState) => state.main.nonce;
export const selectCounter = (state: RootState) => state.main.counter;

export default mainSlice.reducer;
