import React, { useContext, useEffect, useRef, useState } from "react";
import { UIContext } from "../context/UIContext";
import { getTokenMetadata, useTokenInfo } from "../services/queries";
import { axiosInstance } from "../config/config";
export default function IntroUI() {
  const { lbRaceData, lbTokenData, introIndex, entrantStats } =
    useContext(UIContext);

  const entrantIndex = useRef(0);

  const sectionIndex = introIndex <= 7 ? introIndex : 7;
  const [matchingEntrant, setMatchingEntrant] = useState(null);

  useEffect(() => {
    async function getData() {
      let sortedEntrants = lbRaceData.entrants.slice(); // Create a copy to avoid modifying the original array
      sortedEntrants.sort((a, b) => a.joinPos - b.joinPos);

      // Calculate matchingEntrantIndex based on the new sectionIndex
      const matchingEntrantIndex = await findMatchingEntrantData(
        sortedEntrants[entrantIndex.current]?.tokenId,
        sortedEntrants[entrantIndex.current]?.tokenFamily
      );

      console.log("DEBUG matching entrant", matchingEntrantIndex);

      // Set matchingEntrant based on the calculated index
      if (matchingEntrantIndex !== -1) {
        setMatchingEntrant(matchingEntrantIndex);
      }
      entrantIndex.current += 1;
    }

    getData();
  }, [sectionIndex, entrantStats, setMatchingEntrant]);

  async function findMatchingEntrantData(targetTokenId, targetTokenFamily) {
    for (let i = 0; i < entrantStats.length; i++) {
      if (
        entrantStats[i]?.tokenId === targetTokenId &&
        entrantStats[i]?.tokenFamily === targetTokenFamily
      ) {
        return entrantStats[i]; // Return the index of the matching entrant
      }
    }
    return -1; // Return -1 if no matching entrant is found
  }
  return (
    <>
      <section
        key={sectionIndex}
        className="flex flex-col  space-y-10 md:space-y-24 w-full md:w-[40%] p-10 md:p-5  h-full justify-between md:justify-center"
      >
        <div className="space-y-5 md:space-y-10">
          <div
            className={`animate-in slide-in-from-left transition duration-700 flex justify-center md:justify-start md:ml-20`}
          >
            <h1 className="text-white font-audiowide mt-5 md:mt-none text-2xl md:text-7xl  relative">
              <span className=" inset-0 bg-gradient-to-r from-white to-green-400 bg-clip-text text-transparent mix-blend-multiply">
                GATE {introIndex <= 7 ? introIndex + 1 : 7 + 1}
              </span>
            </h1>
          </div>
          <div
            className={`animate-in slide-in-from-left transition duration-700 flex justify-center md:justify-start md:ml-20`}
          >
            <h1 className="text-white font-audiowide mt-5 md:mt-none text-sm md:text-xl  relative">
              <span className="text-white">
                Kennel : {lbTokenData[sectionIndex]?.kennelName}
              </span>
            </h1>
          </div>
          <div
            className={`animate-in slide-in-from-left transition duration-700 ml-3 h-10 md:h-24 bg-green-700 bg-opacity-80 skew-x-[-12deg] relative text-center flex items-center justify-center`}
          >
            <span className="strike" />
            <span className="strike" />
            <span className="strike" />
            <h1 className="text-lg md:text-5xl font-audiowide animate-pulse transition duration-1000">
              {lbTokenData[sectionIndex]?.name || "SCRAPPER"}
            </h1>
          </div>
        </div>

        <div className="grid grid-cols-3 grid-rows-2 w-full gap-16 pb-10 md:pb-0 ">
          {lbTokenData[sectionIndex]?.attributes?.map((attribute, index) => {
            const { trait_type, value } = attribute;

            if (
              trait_type === "Bloodline" ||
              trait_type === "Coat Colour" ||
              trait_type === "Coat Colour4"
            ) {
              // Use regular expression to split the value after the first space
              const [bloodlineCode, bloodlineName] = value.split(/\s(.+)/);

              // Format the value as "G1 - Black Top" for "Bloodline" trait type
              const displayedValue =
                trait_type === "Bloodline"
                  ? `${bloodlineCode} - ${bloodlineName}`
                  : value;

              return (
                <span
                  key={index}
                  className="animate-in slide-in-from-left transition duration-700 flex flex-col justify-center items-center font-audiowide text-center"
                >
                  <p
                    className={`text-gray-500 text-sm md:text-lg whitespace-nowrap`}
                  >
                    {trait_type}
                  </p>
                  <p
                    className={`bg-gray-800 rounded px-2 bg-opacity-90 md:bg-transparent md:px-0 text-green-600 text-sm md:text-lg w-[100px] md:w-full`}
                  >
                    {displayedValue}
                  </p>
                </span>
              );
            }

            return null;
          })}
          <span className="animate-in slide-in-from-left transition duration-700 flex flex-col justify-center items-center font-audiowide text-center">
            <p
              className={` text-gray-500 text-sm md:text-lg whitespace-nowrap`}
            >
              Prize Money
            </p>
            <p
              className={`bg-gray-800 rounded px-2 bg-opacity-90 md:bg-transparent md:px-0 text-green-600 text-sm md:text-lg whitespace-nowrap`}
            >
              ${(matchingEntrant?.total_winnings / 1000000).toFixed(2) || "0"}
            </p>
          </span>

          <span className="animate-in slide-in-from-left transition duration-700 flex flex-col justify-center items-center font-audiowide text-center">
            <p
              className={` text-gray-500 text-sm md:text-lg whitespace-nowrap`}
            >
              Completed Races
            </p>
            <p
              className={`bg-gray-800 rounded px-2 bg-opacity-90 md:bg-transparent md:px-0 text-green-600 text-sm md:text-lg whitespace-nowrap`}
            >
              {matchingEntrant?.completed_races || "No Data"}
            </p>
          </span>

          <span className="animate-in slide-in-from-left transition duration-700 flex flex-col justify-center items-center font-audiowide text-center">
            <p
              className={` text-gray-500 text-sm md:text-lg whitespace-nowrap`}
            >
              Career Stats
            </p>
            <p
              className={`bg-gray-800 rounded px-2 bg-opacity-90 md:bg-transparent md:px-0 text-green-600 text-sm md:text-lg whitespace-nowrap`}
            >
              {matchingEntrant?.career.firsts || "##"} /{" "}
              {matchingEntrant?.career.seconds || "##"} /{" "}
              {matchingEntrant?.career.thirds || "##"}
            </p>
          </span>

          <span className="animate-in slide-in-from-left transition duration-700 flex flex-col justify-center items-center font-audiowide text-center">
            <p className={`text-gray-500 text-sm md:text-lg whitespace-nowrap`}>
              Win Percentage
            </p>
            <p
              className={`bg-gray-800 rounded px-2 bg-opacity-90 md:bg-transparent md:px-0 text-green-600 text-sm md:text-lg whitespace-nowrap`}
            >
              {matchingEntrant?.career.firsts !== undefined &&
              matchingEntrant?.completed_races !== undefined
                ? (
                    (matchingEntrant?.career.firsts /
                      matchingEntrant?.completed_races) *
                    100
                  ).toFixed(2)
                : "0"}
              %
            </p>
          </span>
        </div>
      </section>
    </>
  );
}
