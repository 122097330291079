import React, { useContext } from "react";
import colors from "./Colors.json";
import { sRGBEncoding } from "three";
let counter = 0;
export default async function GenerateMaterial(
  trimmedBloodlineValue,
  textureLoader,
  clonedMaterial,
  colorCoat,
  tokenFamily,
  name
) {
  // construct the URLs using template literals
  const metalnessMapUrl = `/skin-textures/${trimmedBloodlineValue}-Metal.jpg`;
  const normalMapUrl = `/skin-textures/${trimmedBloodlineValue}-NRM.jpg`;

  const diffuseMapUrl =
    "/skin-textures/" +
    tokenFamily +
    "/PATTERN_COLORS/JPEG/T_" +
    tokenFamily +
    "-" +
    colorCoat +
    "_Diff.jpg";

  const diffuseMap = await textureLoader.load(diffuseMapUrl, function () {
    clonedMaterial.normalMap.needsUpdate = true;
  });

  // load the textures using the texture loader
  const metalnessMap = await textureLoader.load(metalnessMapUrl, function () {
    clonedMaterial.metalnessMap.needsUpdate = true;
  });
  const normalMap = await textureLoader.load(normalMapUrl, function () {
    clonedMaterial.normalMap.needsUpdate = true;
  });

  switch (tokenFamily) {
    case "G1":
      await getTexture(
        tokenFamily,
        textureLoader,
        colorCoat,
        clonedMaterial,
        metalnessMap,
        normalMap,
        name,
        diffuseMap
      );

      break;
    case "G2":
      await getTexture(
        tokenFamily,
        textureLoader,
        colorCoat,
        clonedMaterial,
        metalnessMap,
        normalMap,
        name,
        diffuseMap
      );
      break;
    case "G3":
      await getTexture(
        tokenFamily,
        textureLoader,
        colorCoat,
        clonedMaterial,
        metalnessMap,
        normalMap,
        name,
        diffuseMap
      );
      break;
    case "G4":
      // let ogMap = null;
      // if (originalTexture !== null) {
      //   // Do something with originalcolor
      //   ogMap = originalTexture;
      // }
      // for (const key in colors) {
      //   if (key === colorCoat) {
      //     clonedMaterial.map = ogMap;
      //     clonedMaterial.needsUpdate = true;
      //     clonedMaterial.color.set(`#${colors[key]}`);
      //     clonedMaterial.color.needsUpdate = true;
      //   }
      // }
      await getTexture(
        tokenFamily,
        textureLoader,
        colorCoat,
        clonedMaterial,
        metalnessMap,
        normalMap,
        name,
        diffuseMap
      );

      break;

    default:
      break;
  }
}

async function getTexture(
  tokenFamily,
  textureLoader,
  colorCoat,
  clonedMaterial,
  metalnessMap,
  normalMap,
  name,
  diffuseMap
) {
  clonedMaterial.map = diffuseMap;
  // clonedMaterial.metalness = 0;
  clonedMaterial.metalnessMap = metalnessMap;
  clonedMaterial.normalMap = normalMap;
  clonedMaterial.needsUpdate = true;
}
