import React, { createContext, useEffect, useRef, useState } from "react";

// Create a new context
export const UIContext = createContext();

// Create a ContextProvider component
export const UIContextProvider = ({ children }) => {
  const [isIntro, setIsIntro] = useState(true);
  const [introIndex, setIntroIndex] = useState(null);
  const [startTimer, setStartTimer] = useState(10);
  const [loaderProgress, setLoaderProgress] = useState(0);
  const [showCountDown, setShowCountdown] = useState(false);
  const [showReplay, setShowReplay] = useState(false);
  const [startedRace, setStartedRace] = useState(false);
  const [raceStartLength, setRaceStartLength] = useState(0);
  const [fadeIn, setFadeIn] = useState(false);
  const [hideFadeIn, setHideFadeIn] = useState(false);
  const [entrantStats, setEntrantStats] = useState([]);
  const [entrantDataFetched, setentrantDataFetched] = useState(false);
  const [showEndGame, setShowEndGame] = useState(false);
  const [showNameTag, setShowNameTag] = useState(true);
  const [showFinalResults, setFinalResults] = useState([]);
  const [lbTokenData, setlbTokenData] = useState(null);
  const lbRank = useRef([0, 1, 2, 3, 4, 5, 6, 7]);
  const [lbRaceData, setLBRaceData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [showDog, setShowDog] = useState(false);
  const [gameProgress, setGameProgress] = useState(0);
  const currentPosGlobal = useRef();
  const [finishedNames, setFinishedNames] = useState([]);
  const replayTimer = useRef(0);
  const [mute, setMute] = useState(false);
  const [raceState, setRaceState] = useState("prevRace");

  // Function to add a new name to the state without duplicates
  const addNameWithoutDuplicates = (name, prev) => {
    // Check if the name already exists in the current state
    const isDuplicate = prev.includes(name);

    // If it's a duplicate, do nothing and return the current state
    if (isDuplicate) {
      return prev;
    }

    // If it's not a duplicate, add the new name to the state and return the updated state
    return [...prev, name];
  };

  // Function to update finishedNames using the functional update form
  const updateFinishedNames = (name) => {
    setFinishedNames((prev) => addNameWithoutDuplicates(name, prev));
  };

  // Define any functions or state variables that you want to make available to child components

  return (
    // Pass any state or functions down to the child components through the value prop
    <UIContext.Provider
      value={{
        showCountDown,
        setShowCountdown,
        showReplay,
        setShowReplay,
        showEndGame,
        setShowEndGame,
        showNameTag,
        setShowNameTag,
        showFinalResults,
        setFinalResults,
        lbRank,
        lbTokenData,
        setlbTokenData,
        lbRaceData,
        setLBRaceData,
        isLoading,
        setIsLoading,
        gameProgress,
        setGameProgress,
        startTimer,
        setStartTimer,
        loaderProgress,
        setLoaderProgress,
        replayTimer,
        finishedNames,
        setFinishedNames,
        updateFinishedNames,
        startedRace,
        setStartedRace,
        isIntro,
        setIsIntro,
        introIndex,
        setIntroIndex,
        raceStartLength,
        setRaceStartLength,
        fadeIn,
        setFadeIn,
        hideFadeIn,
        setHideFadeIn,
        entrantStats,
        setEntrantStats,
        entrantDataFetched,
        setentrantDataFetched,
        setShowDog,
        showDog,
        mute,
        setMute,
        setRaceState,
        raceState,
        currentPosGlobal,
      }}
    >
      {children}
    </UIContext.Provider>
  );
};
