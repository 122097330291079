import React, { useRef } from "react";
import { useGLTF, useTexture } from "@react-three/drei";
import { Color } from "three";

export function TrackModel(props) {
  const { nodes, materials } = useGLTF("/models/Terrain2.glb");
  const clonedMaterial = materials.RaceTerrain.clone();
  clonedMaterial.color = new Color(0x000000);
  clonedMaterial.metalness = 0;
  clonedMaterial.roughness = 1;

  return (
    <group {...props} dispose={null}>
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Terrain.geometry}
        rotation={[Math.PI / 2, 0, 0]}
        scale={0.01}
        material={clonedMaterial}
      ></mesh>
    </group>
  );
}

useGLTF.preload("/models/Terrain2.glb");
