import * as THREE from "three";
import React, { useRef, useMemo } from "react";
import { useFrame } from "@react-three/fiber";
import Random from "canvas-sketch-util/random";
import { useControls } from "leva";

export function SpaceDust({ count }) {
  const mesh = useRef();
  const light = useRef();

  // Generate some random positions, speed factors, scales, and timings
  const particles = useMemo(() => {
    const temp = [];
    for (let i = 0; i < count; i++) {
      const randomFactor = Math.random(); // Generate a random factor

      // Calculate x position based on the random factor
      let x;
      if (randomFactor < 0.5) {
        x = -20 - Math.random() * 20; // Position between -40 and -20
      } else {
        x = 20 + Math.random() * 20; // Position between 20 and 40
      }

      const y = Random.range(-50, 50);
      const z = Random.range(50, 150);
      let scale;
      if (Math.random() < 0.8) {
        scale = Random.range(0.1, 2); // Smaller rocks
      } else {
        scale = Random.range(2, 5); // Fewer larger rocks
      }

      temp.push({ x, y, z, scale });
    }
    return temp;
  }, [count]);

  const dummy = useMemo(() => new THREE.Object3D(), []);

  // Calculate the common z speed for all particles
  const zSpeed = 1;

  useFrame(() => {
    // Run through the randomized data to calculate movement and scale
    particles.forEach((particle, index) => {
      const { x, y, z, scale } = particle;

      // Update the particle position based on a common z speed
      particle.z -= zSpeed; // Move all particles in the negative Z direction

      // Reset the particle's z position when it goes beyond a threshold
      if (particle.z < -50) {
        particle.z = 150;
        // You can optionally re-randomize the x position or keep it constant
        // particle.x = Random.range(-50, 50); // Re-randomize x position
      }

      dummy.position.set(x, y, z);
      dummy.scale.set(scale, scale, scale); // Apply scale
      dummy.updateMatrix();

      // Apply the matrix to the instanced item
      mesh.current.setMatrixAt(index, dummy.matrix);
    });
    mesh.current.instanceMatrix.needsUpdate = true;
  });

  return (
    <>
      <instancedMesh ref={mesh} args={[null, null, count]}>
        <dodecahedronBufferGeometry args={[0.2, 0]} />
        <meshStandardMaterial color="#ffffff" />
      </instancedMesh>
    </>
  );
}
