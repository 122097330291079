import React, { useState, useEffect, useContext } from "react";
import { UIContext } from "../context/UIContext";

const FadeComponent = () => {
  const { fadeIn, setFadeIn } = useContext(UIContext);
  const fadeClasses = `absolute transition-opacity duration-1000 w-full h-full z-[30] bg-black ${
    fadeIn ? "opacity-100" : "opacity-0"
  }`;

  return (
    <div>
      <div className={fadeClasses}></div>
    </div>
  );
};

export default FadeComponent;
