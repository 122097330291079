import { Routes, Route, Link, Router } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { axiosInstance, CONTRACT_ADDRESS } from "./../config/config";

import "./style.scss";
import View3D from "./contents/races/View3D/View3D";
import HomePage from "../pages/HomePage";
import { UIContextProvider } from "../context/UIContext";
import UI from "../pages/UI";
import { useState } from "react";
export default function Content() {
  const { pathname } = useLocation();
  const [playGame, setPlayGame] = useState(false);

  return (
    <>
      <UIContextProvider>
        <Routes>
          <Route
            path="/race/:uuid"
            element={
              <>
                <UI>
                  <View3D></View3D>
                </UI>
              </>
            }
          />
        </Routes>
      </UIContextProvider>
    </>
  );
}
