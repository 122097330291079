import {
  forwardRef,
  useContext,
  useEffect,
  useRef,
  useState,
  useCallback,
} from "react";
import { useThree } from "@react-three/fiber";
import { Audio, AudioListener, AudioLoader } from "three";
import { UIContext } from "../../context/UIContext";

import useIsTabVisible from "./tabVisible";
let audioListener;

const AudioManager = forwardRef(() => {
  const { isIntro, lbRaceData, showEndGame, mute } = useContext(UIContext);
  const { camera } = useThree();
  const crowdAudioRef = useRef(null);
  const voiceAudioRef = useRef(null);

  const isVisible = useIsTabVisible();

  const [startRaceAudio, setStartRaceAudio] = useState(false);
  const [userHasInteracted, setUserHasInteracted] = useState(false);

  const handlePlayPause = useCallback(() => {
    if (isVisible && !showEndGame && userHasInteracted) {
      crowdAudioRef.current?.play();
      voiceAudioRef.current?.play();
    } else {
      crowdAudioRef.current?.pause();
      voiceAudioRef.current?.pause();
    }
  }, [isVisible, showEndGame, userHasInteracted]);

  const loadAudio = useCallback((url, audioRef, volume, loop) => {
    const audioLoader = new AudioLoader();
    audioLoader.load(url, function (buffer) {
      if (!audioRef.current) {
        const sound = new Audio(audioListener);
        sound.setLoop(loop);
        sound.setVolume(volume);
        sound.setBuffer(buffer);
        sound.play();
        audioRef.current = sound;
      } else {
        audioRef.current.stop();
        audioRef.current.setBuffer(buffer);
        audioRef.current.play();
      }
    });
  }, []);

  // This function will be called when the user interacts with the page.
  function playAudio() {
    if (startRaceAudio === true || isIntro) {
      setUserHasInteracted(true);
      handlePlayPause();
    }
  }

  useEffect(() => {
    // Add an event listener for a click anywhere on the page.
    window.addEventListener("click", playAudio);
    return () => {
      // Clean up the event listener on component unmount
      window.removeEventListener("click", playAudio);
    };
  }, [playAudio]);

  useEffect(() => {
    if (mute) {
      crowdAudioRef.current?.setVolume(0);
      voiceAudioRef.current?.setVolume(0);
    } else if (!isIntro && !mute) {
      crowdAudioRef.current?.setVolume(0.5);
      voiceAudioRef.current?.setVolume(0.7);
    } else if (isIntro && !mute) {
      crowdAudioRef.current?.setVolume(0.05);
    }
  }, [mute, isIntro]);

  useEffect(() => {
    if (lbRaceData?.startTime && !isIntro && !startRaceAudio) {
      const timestamp = lbRaceData?.startTime;

      const interval = setInterval(() => {
        const now = new Date().getTime();
        const distance = timestamp - now;

        if (distance <= 20000) {
          if (
            (lbRaceData.raceDistance === 600 &&
              lbRaceData.entrants.length <= 2) ||
            (lbRaceData.raceDistance === 500 && lbRaceData.entrants.length <= 2)
          ) {
            setTimeout(() => {
              setStartRaceAudio(true);
            }, 2000);
          } else if (
            lbRaceData.raceDistance === 400 &&
            lbRaceData.entrants.length <= 2
          ) {
            setTimeout(() => {
              setStartRaceAudio(true);
            }, 3000);
          } else {
            setStartRaceAudio(true);
          }
        }

        if (distance < 0) {
          clearInterval(interval);
          return;
        }

        return () => clearInterval(interval);
      });
    }
  }, [isIntro, lbRaceData]);

  useEffect(() => {
    audioListener = new AudioListener();
    const raceDistance = lbRaceData?.raceDistance;
    let voicePath, audioPath, introAudioPath;
    if (lbRaceData.entrants.length === 2) {
      voicePath = `/audio/VOICE_1V1_${raceDistance}M.mp3`;
    } else {
      voicePath = `/audio/VOICE_${raceDistance}M.mp3`;
    }
    audioPath = `/audio/BG_${raceDistance}M.mp3`;
    introAudioPath = `/audio/introhiphop.mp3`;

    if (isIntro) {
      loadAudio(introAudioPath, crowdAudioRef, 0.1, true);
    } else if (!isIntro && !startRaceAudio) {
      crowdAudioRef.current?.stop();
      voiceAudioRef.current?.stop();
    } else if (startRaceAudio) {
      loadAudio(audioPath, crowdAudioRef, 0.5, false);
      loadAudio(voicePath, voiceAudioRef, 1, false);
      if (crowdAudioRef.current) {
        crowdAudioRef.current.setVolume(0.2);
      }
    }
    camera.add(audioListener);
  }, [isIntro, loadAudio, startRaceAudio]);

  useEffect(handlePlayPause, [isVisible, showEndGame]);

  // When the component mounts, try to start playing audio
  useEffect(() => {
    handlePlayPause();
  }, [handlePlayPause]);

  return <></>;
});

export default AudioManager;
