import {
  Suspense,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { extend, useFrame, useLoader, useThree } from "@react-three/fiber";
import * as THREE from "three";
import { useBox } from "@react-three/cannon";
import { AnimationMixer, Vector3, TextureLoader, Clock, Color } from "three";
import NameTag from "../Sprites/NameTag";
import { TrailModel } from "../../Trail/TrailPlane";
import { MeshBasicMaterial } from "three";
import { Outline, Selection } from "@react-three/postprocessing";
import { UIContext } from "../../../../../../context/UIContext";
import {
  getTokenMetadata,
  useCurrentRace,
} from "../../../../../../services/queries";
import { useNavigate, useParams } from "react-router";

const ROAD_END = 79;
const FIT_GROUND = 1.55;

const Model = ({
  model,
  modelObj,
  raceDist,
  raceSpeed,
  initPos,
  isStart,
  idx,
  animations,
  SCALE,
  orbitControlRef,
  rank,
  raceReplayTime,
  isReplay,
  startPosZ,
  name,
}) => {
  const [ref, api] = useBox(() => ({
    mass: 0,
    type: "Dynamic",
    position: [initPos.x, 0, startPosZ - FIT_GROUND],
    velocity: [0, 0, 0],
  }));
  const { uuid } = useParams();

  const [mixer, setMixer] = useState(null);

  const { camera, scene } = useThree();
  const direction = useRef(new Vector3(0, 0, 1));
  const [clock] = useState(new Clock());
  const actionRef = useRef();

  const position = useRef([initPos.x, 0, startPosZ - FIT_GROUND]);
  const replayPosition = useRef([initPos.x, 0, startPosZ - FIT_GROUND]);

  const {
    updateFinishedNames,
    lbRaceData,
    isIntro,
    startTimer,
    setLBRaceData,
    currentPosGlobal,
  } = useContext(UIContext);

  const [maxRaceTime, setMaxRaceTime] = useState(0);

  const [finishedRace, setFinishedRace] = useState(false);
  const localPosZRef = useRef(null);

  const fixedTimeStep = 1 / 60; // Example fixed time step of 60 FPS
  let lastTime = 0;
  let accumulatedTime = 0;

  const [outcome, setOutcome] = useState([]);

  useEffect(() => {
    async function getOutcome() {
      const newOutcome = [];
      if (lbRaceData.outcome?.length) {
        for (let i = 0; i < lbRaceData.outcome.length; i++) {
          const tokenInfo = await getTokenMetadata(
            lbRaceData.outcome[i].tokenId,
            lbRaceData.outcome[i].tokenFamily
          );
          newOutcome.push(tokenInfo.name);
        }
        setOutcome(newOutcome);
      }
    }

    getOutcome();
  }, [lbRaceData]);

  useEffect(() => {
    if (lbRaceData.outcome) {
      // Map over the array and extract the time property of each object
      const times = lbRaceData.outcome.map((outcomeObj) => outcomeObj.time);

      // Find the maximum time
      const maxTime = Math.max(...times);

      setMaxRaceTime(maxTime.toFixed(2) - 1.5);
    }
  }, [lbRaceData]);

  useEffect(() => {
    const unsubscribe = api.position.subscribe((v) => {
      if (idx === rank[0] && isStart && v[2] > startPosZ) {
        const dis = new Vector3(0, 0, v[2]).sub(
          new Vector3(
            position.current[0],
            position.current[1],
            position.current[2]
          )
        );
        orbitControlRef.current.target.set(0.965 - 0.595 * 4, 0, v[2] - 1);
        camera.position.addScaledVector(direction.current, dis.z);
      }
      //TODO

      if (isStart) {
        if (currentPosGlobal.current < ROAD_END - 2) {
          // Update replayPosition to be 10 units further along the z-axis than v
          replayPosition.current = v;
        }
      }
      position.current = v;
    });

    return unsubscribe;
  }, [isStart, rank[0], startPosZ]);

  useEffect(() => {
    if (!model) return;

    setMixer(new AnimationMixer(model));
  }, [model, animations]);

  useEffect(() => {
    onPlayAnimation();
  }, [mixer, isStart, isReplay]);

  const onPlayAnimation = () => {
    if (mixer && animations.length > 0) {
      if ((isStart || isReplay) && animations[3]) {
        if (isStart) {
          if (actionRef.current) {
            actionRef.current.stop();
          }
          actionRef.current = mixer.clipAction(animations[3], null, 2500);

          // Adjust the speed by modifying the duration
          actionRef.current.setDuration(
            animations[3].duration * (1 + 0.2 * (Math.random() - 0.5)) * 0.7 // Speed up by 30%
          );

          actionRef.current.play();
        } else {
          if (actionRef.current) {
            actionRef.current.stop();
          }
          actionRef.current = mixer.clipAction(animations[3], null, 2500);
          // Add a random value between -0.1 and 0.1 to the duration of the animation
          actionRef.current.setDuration(
            animations[3].duration * 5 * (1 + 0.2 * (Math.random() - 0.5))
          );
          actionRef.current.play();
        }
      } else {
        if (actionRef.current && position.current[2] < ROAD_END) {
          actionRef.current.stop();
        }
        if (position.current[2] < ROAD_END) {
          actionRef.current = mixer.clipAction(
            animations[Math.floor(Math.random() * 3)],
            null,
            2500
          );
          actionRef.current.play();
          actionRef.current.time = Math.floor(Math.random() * 2);
        }
      }
    }
  };

  useEffect(() => {
    if (isStart) {
      clock.start();
    } else {
      clock.stop();
    }
  }, [isStart]);

  useEffect(() => {
    async function getNewRaceData() {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}races/${uuid}`
      );
      let data = await response.json();

      setLBRaceData(data);
    }
    getNewRaceData();
  }, [startTimer]);

  useEffect(() => {
    if (isReplay) {
      api.position.set(
        replayPosition.current[0],
        replayPosition.current[1],
        replayPosition.current[2]
      );
      api.velocity.set(0, 0, 0);
    }
  }, [isReplay]);

  // useEffect(() => {
  //   // When localPosZRef.current changes (new frame), trigger the state update

  //   if (localPosZRef.current > raceDist[raceDist.length - 1] / 10 + 0.5) {
  //     setTimeout(() => {
  //       updateFinishedNames(name);
  //     }, 500); // delay of 500 milliseconds (0.5 seconds)
  //   }
  // }, [localPosZRef.current, raceDist, name]);

  function findIndexByName(name) {
    for (let index = 0; index < outcome.length; index++) {
      if (name === outcome[index]) {
        return index;
      }
    }
    // If the name is not found in the array, return -1 to indicate that.
    return -1;
  }

  useFrame((state, delta) => {
    accumulatedTime += delta;
    if (mixer) {
      mixer.update(delta);
    }
    if (lbRaceData.raceDists?.length > 0 && lbRaceData.raceSpeeds?.length > 0) {
      raceDist = lbRaceData.raceDists[idx];
      raceSpeed = lbRaceData.raceSpeeds[idx];
    }
    let resultIndex = findIndexByName(name) + 1;
    while (accumulatedTime >= fixedTimeStep) {
      if (isStart) {
        // Set the rotation of an object to zero.

        api.rotation.set(0, 0, 0);

        // Calculate the local position of the object along the z-axis.
        const localPosZ = position.current[2] - startPosZ + FIT_GROUND;
        localPosZRef.current = localPosZ;

        // Find the current position's index in the raceDist array.
        const indexV = raceDist.findIndex(
          (dist, i) =>
            dist * SCALE > localPosZ &&
            (i === 0 || raceDist[i - 1] * SCALE <= localPosZ)
        );

        const maxDogs = outcome.length;
        const velocityAdjustment =
          0.05 * (1 - (resultIndex - 1) / (maxDogs - 1)); // Adjust the coefficient as needed.

        // Calculate the adjusted velocity based on the raceSpeed data for the current position and resultIndex.
        if (indexV >= 0 && indexV < raceSpeed.length && ref.current) {
          const adjustedVelocity =
            raceSpeed[indexV] * SCALE * (1 + velocityAdjustment);

          api.velocity.set(0, 0, adjustedVelocity);
        }

        // If the dog crosses the finish line, update the finished names after a delay.
        if (localPosZ > lbRaceData?.raceDistance / 10 + 0.109) {
          updateFinishedNames(name);
        }
      } else if (isReplay) {
        api.rotation.set(0, 0, 0);

        let localPosZ = position.current[2] - startPosZ + FIT_GROUND;

        // Exit if the local position surpasses the total race distance.
        if (localPosZ > raceDist[raceDist.length - 1] * SCALE) {
          return;
        }

        // Find the current position's index in the raceDist array.
        const indexV = raceDist.findIndex(
          (dist, i) =>
            dist * SCALE > localPosZ &&
            (i === 0 || raceDist[i - 1] * SCALE <= localPosZ)
        );

        const maxDogs = outcome.length;
        const velocityAdjustment =
          0.03 * (1 - (resultIndex - 1) / (maxDogs - 1));

        // Set the velocity of the object based on the raceSpeed data for the current position.
        if (indexV >= 0 && ref.current) {
          const adjustedVelocity =
            raceSpeed[indexV] * SCALE * (1 + velocityAdjustment);

          api.velocity.set(0, 0, adjustedVelocity / 5);
        }

        // If the dog reaches the end during replay, mark the race as finished.
        if (localPosZ >= lbRaceData?.raceDistance / 10) {
          setFinishedRace(true);
        }
      }
      accumulatedTime -= fixedTimeStep;
    }
  });
  const modelRef = useRef();

  // Create a green wireframe material for the outline
  const outlineMaterial = new MeshBasicMaterial({
    color: 0x00ff00,
    wireframe: true,
    side: THREE.BackSide,
  });

  // Define a function to update the model material
  const updateModelMaterial = () => {
    if (finishedRace) {
      model.traverse((child) => {
        if (child.isMesh) {
          child.material = outlineMaterial;
        }
      });
    }
  };

  // Call the updateModelMaterial function when the finishedRace state changes
  useEffect(() => {
    updateModelMaterial();
  }, [finishedRace]);

  return (
    <group ref={ref}>
      {!isIntro && (
        <>
          <NameTag name={name} />
          {isStart && <TrailModel />}
          <primitive object={model} userData={{ gate: idx }} />
        </>
      )}
    </group>
  );
};

export default Model;
