import React, { useRef } from "react";
import { useGLTF, useTexture } from "@react-three/drei";
import { Color, MeshBasicMaterial, MeshLambertMaterial, Vector2 } from "three";

export function TrackOnly(props) {
  const { nodes, materials } = useGLTF("/models/TrackOnlyDarker.glb");

  const clonedMaterial = materials.M_Race_01_Terrain.clone();

  const clonedBlack = materials.M_Black.clone();

  clonedBlack.metalness = 1;
  clonedBlack.roughness = 0.3;

  const newMaterial = new MeshLambertMaterial({
    emissive: clonedMaterial.emissive,
    emissiveMap: clonedMaterial.emissiveMap,
    map: clonedMaterial.map,
    transparent: true,
    opacity: 1,
    color: "#0000FF",
  });
  return (
    <group {...props} dispose={null}>
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Track_01.geometry}
        material={clonedBlack}
        rotation={[Math.PI / 2, 0, 0]}
        scale={0.01}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Track_03.geometry}
        material={clonedBlack}
        rotation={[Math.PI / 2, 0, 0]}
        scale={0.01}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Track_05.geometry}
        material={clonedBlack}
        rotation={[Math.PI / 2, 0, 0]}
        scale={0.01}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Track_07.geometry}
        material={clonedBlack}
        rotation={[Math.PI / 2, 0, 0]}
        scale={0.01}
      />
      <group rotation={[Math.PI / 2, 0, 0]} scale={0.01}>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Mesh027.geometry}
          material={newMaterial}
        ></mesh>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Mesh027_1.geometry}
          material={clonedBlack}
        />
      </group>
      <group rotation={[Math.PI / 2, 0, 0]} scale={0.01}>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Mesh030.geometry}
          material={newMaterial}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Mesh030_1.geometry}
          material={clonedBlack}
        />
      </group>
      <group rotation={[Math.PI / 2, 0, 0]} scale={0.01}>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Mesh032.geometry}
          material={newMaterial}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Mesh032_1.geometry}
          material={clonedBlack}
        />
      </group>
      <group rotation={[Math.PI / 2, 0, 0]} scale={0.01}>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Mesh034.geometry}
          material={newMaterial}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Mesh034_1.geometry}
          material={clonedBlack}
        />
      </group>
    </group>
  );
}

useGLTF.preload("/models/TrackOnlyDarker.glb");
