import { useFrame, useLoader } from "@react-three/fiber";
import { useControls } from "leva";
import React, { useContext, useEffect, useRef, useState } from "react";
import { AnimationMixer, Color, LoopRepeat, TextureLoader } from "three";
import { DRACOLoader } from "three/examples/jsm/loaders/DRACOLoader";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import { UIContext } from "../../../../../../context/UIContext";
import GenerateMaterial from "../Model/ModelMaterials";
import { EffectComposer, Glitch, Vignette } from "@react-three/postprocessing";
import { useMediaQuery } from "react-responsive";
import { getTokenMetadata } from "../../../../../../services/queries";

const texturesData = [
  {
    src: "/textures/T_Number_01_Dif.jpg",
  },
  {
    src: "/textures/T_Number_02_Dif.jpg",
  },
  {
    src: "/textures/T_Number_03_Dif.jpg",
  },
  {
    src: "/textures/T_Number_04_Dif.jpg",
  },
  {
    src: "/textures/T_Number_05_Dif.jpg",
  },
  {
    src: "/textures/T_Number_06_Dif.jpg",
  },
  {
    src: "/textures/T_Number_07_Dif.jpg",
  },
  {
    src: "/textures/T_Number_08_Dif.jpg",
  },
];

export default function SingleDog() {
  // const { position, rotation, target } = useControls("Dog", {
  //   position: { value: { x: 0, y: 0, z: 0 }, step: 0.05 },
  //   rotation: { value: { x: 0, y: 0, z: 0 }, step: 0.05 },
  //   target: { value: { x: 0, y: 0, z: 0 }, step: 0.05 },
  // });

  const isDesktopOrLaptop = useMediaQuery({
    query: "(min-width: 1224px)",
  });
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1224px)" });

  const textures = useLoader(
    TextureLoader,
    texturesData.map((i) => i.src)
  );
  const numNormal = useLoader(TextureLoader, "/textures/T_Number_Nrm.jpg");
  const numRou = useLoader(TextureLoader, "/textures/T_Number_Rou.jpg");

  const {
    lbRaceData,
    lbTokenData,
    introIndex,
    setIntroIndex,
    isLoading,
    raceStartLength,
    setFadeIn,
    setIsIntro,
    showDog,
  } = useContext(UIContext);

  const textureLoader = new TextureLoader();

  useEffect(() => {
    if (!isLoading && lbTokenData) {
      const initialIndex = Object.keys(lbTokenData).findIndex(
        (key) => lbTokenData[key] !== 0
      );

      if (initialIndex !== -1) {
        setIntroIndex(initialIndex);
      }

      const interval = setInterval(() => {
        setIntroIndex((prev) => {
          let newIndex = prev + 1;

          while (newIndex < Object.keys(lbTokenData).length) {
            if (lbTokenData[newIndex] !== 0) {
              return newIndex;
            }

            newIndex++;
          }

          clearInterval(interval);
          setFadeIn(true);

          setTimeout(() => {
            setIsIntro(false);

            setTimeout(() => {
              setFadeIn(false);
            }, 2000);
          }, 3000);
          return prev;
        });
      }, 7000);

      return () => {
        clearInterval(interval);
      };
    }
  }, [lbTokenData, setIntroIndex, isLoading]);

  const [mixer, setMixer] = useState(null);
  const actionRef = useRef();

  const gltfs = useLoader(GLTFLoader, "/models/SingleDog.glb", (loader) => {
    const dracoLoader = new DRACOLoader();
    dracoLoader.setDecoderPath(
      "https://www.gstatic.com/draco/versioned/decoders/1.5.5/"
    );
    loader.setDRACOLoader(dracoLoader);
  });

  useFrame((state, delta) => {
    if (gltfs.scene && raceStartLength) {
      if (isDesktopOrLaptop) {
        gltfs.scene.position.set(-1.35, 0, 5.2);
        gltfs.scene.rotation.set(0, -2.65, 0);
      } else if (isTabletOrMobile) {
        gltfs.scene.position.set(-0.95, 0, 4.6);
        gltfs.scene.rotation.set(0, -2.3, 0);
      }
    }

    if (mixer) {
      mixer.update(delta);
    }
  });

  useEffect(() => {
    if (introIndex >= 0 && lbTokenData && gltfs.scene) {
      const sectionIndexSanitized = introIndex <= 7 ? introIndex : 7;
      if (
        introIndex <= 7 &&
        lbTokenData[sectionIndexSanitized] !== 0 &&
        introIndex !== null
      ) {
        const bloodlineValue = lbTokenData[
          sectionIndexSanitized
        ].attributes?.find((attr) => attr.trait_type === "Bloodline")?.value;
        const coatColorValue = lbTokenData[
          sectionIndexSanitized
        ].attributes?.find(
          (attr) =>
            attr.trait_type === "Coat Colour" ||
            attr.trait_type === "Coat Colour4"
        )?.value;

        const name = lbTokenData[sectionIndexSanitized].name;

        const trimmedBloodlineValue = bloodlineValue.replace(/\s+/g, "");
        const trimmedCoatColorValue = coatColorValue.replace(/\s+/g, "");
        const tokenFamily = bloodlineValue.slice(0, 2);

        gltfs.scene.traverse(function (child) {
          if (child.isMesh) {
            if (
              tokenFamily === "G1" ||
              tokenFamily === "G2" ||
              tokenFamily === "G3" ||
              tokenFamily === "G4"
            ) {
              if (child.material.name === "M_Dog_Cloth") {
                child.material.map = textures[sectionIndexSanitized]; // set the texture

                child.material.normalMap = numNormal; // set the normal map
                child.material.roughnessMap = numRou; // set the roughness map

                // Disable reflections and metallic properties
                child.material.envMap = null;
                child.material.metalness = 0;
                child.material.roughness = 1;
              } else if (child.material.name === "M_Dog_Skin") {
                GenerateMaterial(
                  trimmedBloodlineValue,
                  textureLoader,
                  child.material,
                  trimmedCoatColorValue,
                  tokenFamily,
                  name
                );
              }
            }
            child.material.needsUpdate = true;
          }
        });
      }
    }
  }, [introIndex, lbTokenData]);

  useEffect(() => {
    if (gltfs.scene) {
      setMixer(new AnimationMixer(gltfs.scene));
    }
  }, [gltfs.scene]);

  useEffect(() => {
    if (mixer) {
      const randomAnimationIndex = Math.floor(Math.random() * 3); // Generate a random number between 0 and 2
      actionRef.current = mixer.clipAction(
        gltfs.animations[randomAnimationIndex],
        null,
        2500
      );

      // Smoothly fade out the current action
      actionRef.current.fadeOut(0.5); // Adjust the fade duration as needed
      // Smoothly fade in and play the new action
      actionRef.current.fadeIn(0.5); // Adjust the fade duration as needed
      actionRef.current.play();

      return () => {
        // Cleanup function to stop the action when the component unmounts
        actionRef.current.stop();
      };
    }
  }, [mixer, introIndex]);

  return (
    <>
      <EffectComposer>
        <Vignette offset={0.3} darkness={1} />
        <Glitch
          delay={[6.5, 6.5]}
          duration={[0.2, 0.3]}
          strength={[0.4, 0.7]}
        />
      </EffectComposer>
      {showDog && <primitive object={gltfs.scene} />}
    </>
  );
}
