import "./App.css";
import * as react from "react";
import { Provider } from "react-redux";
import { store } from "./app/store";
import Main from "./components/main";

function App() {
  return (
    <>
      <Provider store={store}>
        <Main></Main>
      </Provider>
    </>
  );
}

export default App;
