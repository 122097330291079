import { shaderMaterial } from "@react-three/drei";
import * as THREE from "three";
import { extend, useFrame } from "@react-three/fiber";
import { useRef } from "react";

const GradientMaterial = shaderMaterial(
  {
    uTime: 0,
    uSpeed: 1.0, // speed of the wind effect
    uColorStart: new THREE.Color("#00ff00"), // neon green
    uColorEnd: new THREE.Color("#00ffff"), // neon blue
  },
  `
      uniform float uTime;
      uniform float uSpeed;
    
      varying vec2 vUv;
    
      void main() {
        vUv = uv;
        vUv.x += sin(vUv.y * 10.0 + uTime * uSpeed) * 0.1; // move the gradient from right to left using a sin function
        gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
      }
    `,
  `
      uniform float uTime;
      uniform vec3 uColorStart;
      uniform vec3 uColorEnd;
    
      varying vec2 vUv;
    
      void main() {
        vec3 color = mix(uColorStart, uColorEnd, clamp(vUv.x * 1.25 - 0.25, 0.0, 1.0) * 0.8);
        gl_FragColor = vec4(color, 1.0 - clamp(vUv.x * 1.25 - 0.25, 0.0, 1.0));
      }
    `
);

extend({ GradientMaterial });

export function TrailModel() {
  const materialRef = useRef();
  useFrame((state, delta) => {
    materialRef.current.uniforms.uTime.value += delta * 10; // pass the elapsed time to the shader
  });
  return (
    <mesh
      rotation={new THREE.Euler(0, 1.6, 0)}
      position={new THREE.Vector3(-0.03, 0.45, -0.8)}
    >
      <planeGeometry args={[2, 0.1, 10, 10]} />
      <gradientMaterial
        ref={materialRef}
        side={THREE.DoubleSide}
        transparent={true}
      />
    </mesh>
  );
}
