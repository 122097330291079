import { useFrame } from "@react-three/fiber";
import React, { useContext, useEffect, useState } from "react";
import { UIContext } from "../context/UIContext";
import { FaFlag } from "react-icons/fa";

export default function GameProgress() {
  const {
    gameProgress,
    lbRaceData,
    startTimer,
    replayTimer,
    startedRace,
    setRaceState,
    raceState,
  } = useContext(UIContext);

  const ROAD_END = 79;

  const maxTime = lbRaceData.raceTime;
  const percentage = Math.min(Math.floor((gameProgress / ROAD_END) * 100), 100);

  const [countdown, setCountdown] = useState("");

  const [hasRun, setHasRun] = useState(false);

  let timeValue;
  if (startTimer > 0) {
    timeValue = startTimer;
  } else {
    timeValue = Math.floor((maxTime * percentage) / 100);
  }
  const formattedTimeValue = timeValue.toString().padStart(2, "0").slice(-2);

  useEffect(() => {
    replayTimer.current = parseFloat(formattedTimeValue) - 0.2;
  }, [formattedTimeValue]);

  useEffect(() => {
    if (lbRaceData?.startTime && !hasRun) {
      const timestamp = lbRaceData.startTime;
      const now = new Date().getTime();
      const distance = timestamp - now;

      if (distance <= 0) {
        setRaceState("prevRace");
      } else {
        setRaceState("liveRace");
      }

      setHasRun(true);
    }
  }, [lbRaceData, hasRun]);

  useEffect(() => {
    if (lbRaceData?.startTime) {
      const timestamp = lbRaceData?.startTime;
      const now = new Date().getTime();
      const distance = timestamp - now;

      if (distance <= 0) {
        setCountdown("Race replay...");
      } else {
        const interval = setInterval(() => {
          const now = new Date().getTime();
          const distance = timestamp - now;

          if (distance <= 0) {
            clearInterval(interval);
            setCountdown("Race replay...");
          } else {
            const hours = Math.floor(distance / (1000 * 60 * 60));
            const minutes = Math.floor(
              (distance % (1000 * 60 * 60)) / (1000 * 60)
            );
            const seconds = Math.floor((distance % (1000 * 60)) / 1000);
            const secondsStr = seconds.toString().padStart(2, "0");
            setCountdown(
              `Race will start... ${hours}:${minutes}:${secondsStr}`
            );
          }
        }, 1000);

        return () => clearInterval(interval);
      }
    }
  }, [lbRaceData]);

  return (
    <>
      <div className="absolute w-full md:flex justify-center  mt-[2.7rem] hidden md:visible">
        <h1 className="font-audiowide text-white text-md md:text-3xl">
          {startedRace
            ? raceState === "prevRace"
              ? "Race Replay"
              : "Racing is Live"
            : startTimer <= 5
            ? `Race will start... 0:0:${formattedTimeValue}`
            : countdown}
        </h1>
      </div>
      <section className="mt-2 flex w-full flex-col items-center justify-center self-start md:mb-10 md:self-end">
        <div className="visible md:hidden">
          <h1 className="font-audiowide text-white text-md md:text-lg">
            {startedRace
              ? raceState === "prevRace"
                ? "Race Replay"
                : "Racing is Live"
              : startTimer <= 5
              ? `Race will start... 0:0:${formattedTimeValue}`
              : countdown}
          </h1>
        </div>

        <div className="w-2/4 rounded-br-full rounded-tl-full border-2 border-green-500 bg-gray-700 bg-opacity-80  md:w-1/4">
          <div
            className={`rounded-br-full rounded-tl-full p-0.5 text-center font-audiowide text-xs ${
              percentage > 0 ? "bg-green-500" : ""
            }`}
            style={{ width: `${percentage}%`, color: "white" }}
          >
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <span className="ml-4">{percentage}%</span>
              {percentage > 10 && (
                <span className="relative">
                  <FaFlag className="absolute ml-2" />
                </span>
              )}
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
