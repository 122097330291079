/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import { Clone, useGLTF, useTexture } from "@react-three/drei";
import {
  Color,
  MeshMatcapMaterial,
  MeshStandardMaterial,
  Vector3,
} from "three";

useGLTF.preload("/models/Building.glb");

export function ClonedBuildings() {
  const { scene } = useGLTF("/models/Building.glb");
  const buildingNormalMap = useTexture("/textures/T_Envt_Buildings_Nrm.jpg");
  const buildingDiffuseMap = useTexture("/textures/T_Envt_Buildings_Diff.jpg");

  // Create an array to store the cloned objects
  const clones = [];

  // Number of clones
  const numClones = 26;

  const staticYPositions = [
    -0.4, -0.35, -0.3, -0.25, -0.2, -0.15, -0.1, -0.05, 0, 0.05, 0.1, 0.15, 0.2,
    0.25, 0.3, 0.35, 0.4, 0.45, 0.5, 0.45, 0.4, 0.35, 0.3, 0.25, 0.2, 0.15, 0.1,
  ];

  // Loop to create and position the clones
  for (let i = 0; i < numClones; i++) {
    const clone = scene.clone(); // Clone the original scene
    clone.position.set(0, Math.random(-0.4, 0.5), i * 5.2); // Adjust the z position (you can change this as needed)

    // Traverse through the cloned scene to update materials
    clone.traverse((child) => {
      if (child.isMesh) {
        if (!child.name.endsWith("_1")) {
          child.material = new MeshStandardMaterial({
            map: buildingDiffuseMap,
            normalMap: buildingNormalMap,
            color: new Color("#333333"),
          });
          child.material.needsUpdate = true;
        } else {
          child.material = new MeshMatcapMaterial({
            color: new Color("#292929"), // specify the matcap texture here
          });
          child.material.needsUpdate = true;
        }
      }
    });

    clones.push(clone); // Add the clone to the array
  }

  return (
    <>
      {clones.map((clone, index) => (
        <Clone key={index} object={clone} />
      ))}
    </>
  );
}
