import { useFrame, useThree } from "@react-three/fiber";
import { useContext } from "react";
import gsap from "gsap";
import {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import { Audio, AudioListener, AudioLoader, Matrix4, Vector3 } from "three";
import { UIContext } from "../../../../../../context/UIContext";
import { useControls } from "leva";
import { useMediaQuery } from "react-responsive";
const distance = 0.595;
const ROAD_LENGTH = 79.15;

const CameraControl = forwardRef(
  (
    { orbitControlRef, raceData, SCALE, startPosZ, onHandleStart = () => {} },
    ref
  ) => {
    const {
      setShowCountdown,
      startTimer,
      showCountDown,
      lbRaceData,
      isIntro,
      isLoading,
      setHideFadeIn,
      setShowDog,
      setShowNameTag,
    } = useContext(UIContext);

    const highestJoinPos = useRef(0);
    const oldCameraTarget = useRef(null);

    const { camera } = useThree();
    const isDesktopOrLaptop = useMediaQuery({
      query: "(min-width: 1224px)",
    });
    const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1224px)" });

    useEffect(() => {
      lbRaceData.entrants.forEach((entrant) => {
        if (entrant.joinPos > highestJoinPos.current) {
          highestJoinPos.current = entrant.joinPos;
        }
      });
    }, [lbRaceData]);

    // const { position, rotation, target } = useControls("Camera", {
    //   position: { value: { x: 0, y: 0, z: 0 }, step: 0.05 },
    //   rotation: { value: { x: 0, y: 0, z: 0 }, step: 0.05 },
    //   target: { value: { x: 0, y: 0, z: 0 }, step: 0.05 },
    // });

    // const prevPosition = useRef(position);

    // useFrame(() => {
    //   if (
    //     position.x !== prevPosition.current.x ||
    //     position.y !== prevPosition.current.y ||
    //     position.z !== prevPosition.current.z
    //   ) {
    //     camera.position.set(
    //       camera.position.x + position.x - prevPosition.current.x,
    //       camera.position.y + position.y - prevPosition.current.y,
    //       camera.position.z + position.z - prevPosition.current.z
    //     );
    //     prevPosition.current = position;
    //     console.log(camera.position);
    //   }
    //   // camera.lookAt(new Vector3(target.x, target.y, target.z));
    // });

    useImperativeHandle(ref, () => ({
      onStart: () => moveCamera(),
    }));

    function moveCamera() {}

    useEffect(() => {
      if (startTimer === 4) {
        setShowNameTag(false);
        onHandleStart();
      }
    }, [startTimer]);

    useEffect(() => {
      camera.position.set(0, 5, 0);
      if (orbitControlRef && isIntro && !isLoading) {
        console.log("INTRO");
        // INTRO
        if (oldCameraTarget.current === null) {
          oldCameraTarget.current = orbitControlRef.current.target.clone();
        }
        gsap.to(camera.position, {
          x: -2.0539560000000152,
          y: 0.6199370000000157,
          z: 5 - 0.35,
          duration: 1,
          ease: "none",
        });

        gsap.to(orbitControlRef.current.target, {
          x: 17.5,
          y: -7.35,
          z: 1.65,
          duration: 3,
          ease: "none",
          onStart: () => {
            setShowDog(true);
          },
        });
      }
      if (orbitControlRef && !isIntro) {
        // GAME START
        console.log("GAME START");
        camera.position.set(-1.2, 1, startPosZ + 8);

        if (oldCameraTarget.current === null) {
          oldCameraTarget.current = orbitControlRef.current.target.clone();
        }
        orbitControlRef.current.target = oldCameraTarget.current;

        gsap.to(orbitControlRef.current.target, {
          x: -1.25,
          y: 0,
          z: 0,
          duration: 2.732439386 * 2,
          ease: "none",
          onComplete: () => {
            console.log("FIRST CAM");
            setHideFadeIn(true);
          },
        });

        if (isDesktopOrLaptop) {
          gsap.to(camera.position, {
            x: -1.2,
            y: 0.75,
            z: startPosZ + 1.4 - 0.35,
            duration: 16.7,
            ease: "none",
            onComplete: () => {
              console.log("SECOND CAM");
              setShowCountdown(true);
            },
          });
        } else {
          gsap.to(camera.position, {
            x: -1.2,
            y: 0.75,
            z: startPosZ + 3,
            duration: 16.7,
            ease: "none",
            onComplete: () => {
              console.log("SECOND CAM");
              setShowCountdown(true);
            },
          });
        }
      }
    }, [orbitControlRef, isLoading, isIntro]);

    return <></>;
  }
);

export default CameraControl;
