import React, { useContext, useEffect, useState } from "react";
import CountDown from "../UIComponents/CountDown";
import Replay from "../UIComponents/Replay";
import EndGameMenu from "../UIComponents/EndGameMenu";
import GameNav from "../UIComponents/GameNav";
import Leaderboard from "../UIComponents/LeaderBoard";
import Loader from "../components/loader/loading";
import { UIContext } from "../context/UIContext";
import GameProgress from "../UIComponents/GameProgress";
import Results from "../UIComponents/Results";
import IntroUI from "../UIComponents/IntroUI";
import FadeComponent from "../UIComponents/Fade";
import { GiSpeaker, GiSpeakerOff } from "react-icons/gi";
import { BsArrowsFullscreen } from "react-icons/bs";
import { FullScreen, useFullScreenHandle } from "react-full-screen";

function UI(props) {
  const {
    isLoading,
    isIntro,
    introIndex,
    setIsIntro,
    setFadeIn,
    hideFadeIn,
    mute,
    setMute,
    lbRaceData,
  } = useContext(UIContext);
  const [loadBackground, setLoadBackground] = useState(false);

  useEffect(() => {
    if (!isLoading) {
      const timer = setTimeout(() => {
        setLoadBackground(true);
      }, 1000);

      return () => clearTimeout(timer);
    }
  }, [isLoading]);

  let distance = 0;
  const handle = useFullScreenHandle();

  if (lbRaceData?.startTime) {
    const timestamp = lbRaceData?.startTime;
    const now = new Date().getTime();
    distance = timestamp - now;
  }

  return (
    <FullScreen handle={handle}>
      <nav className="relative z-10  bg-gray-800">
        {isLoading && (
          <div className="absolute z-[999] flex h-full w-full items-center justify-center overflow-y-scroll text-white  ">
            <Loader />
          </div>
        )}
        {!hideFadeIn && <FadeComponent />}

        {!isLoading && isIntro && (
          <>
            {loadBackground && (
              <>
                <div className="absolute flex h-full w-full items-start md:items-center md:justify-start overflow-y-scroll text-white z-20">
                  <IntroUI />
                </div>

                <div className="absolute flex h-full w-full items-end justify-center z-50">
                  <div className="flex self-start  absolute top-0 right-0 p-5 items-center gap-2">
                    <button className="hover:cursor-pointer text-white ">
                      {mute ? (
                        <GiSpeakerOff
                          size={40}
                          onClick={() => {
                            setMute((prev) => !prev);
                          }}
                        />
                      ) : (
                        <GiSpeaker
                          size={40}
                          onClick={() => {
                            setMute((prev) => !prev);
                          }}
                        />
                      )}
                    </button>
                    <BsArrowsFullscreen
                      className=" text-white hover:cursor-pointer"
                      size={25}
                      onClick={handle.enter}
                    />
                  </div>

                  {/* <button
                    className="hover:cursor-pointer text-white self-start items-end absolute top-0 right-0 p-10 bg-blue-500"
                   
                  >
                    FULL SCREEN
                  </button> */}

                  {distance <= 0 && (
                    <button
                      className="font-audiowide text-white mb-5 text-2xl animate-pulse"
                      onClick={() => {
                        setFadeIn(true);

                        setTimeout(() => {
                          setIsIntro(false);

                          setTimeout(() => {
                            setFadeIn(false);
                          }, 2000);
                        }, 3000);
                      }}
                    >
                      SKIP INTRO
                    </button>
                  )}
                </div>

                <section
                  className="hidden md:block"
                  key={introIndex <= 7 ? introIndex : 7}
                >
                  <div className="animate-in slide-in-from-left transition duration-500 absolute flex h-full w-full z-10">
                    <div className="z-10 text-white  w-1/2 bg-gray-800 bg-opacity-60 custom-shape transform skew-x-[-12deg] translate-x-[-8rem]"></div>
                  </div>
                  <div className="animate-in slide-in-from-left transition duration-1000 absolute flex h-full w-full z-10 ">
                    <div className="z-10 text-white  w-1/2 bg-gray-700 bg-opacity-40 custom-shape transform skew-x-[-8deg] translate-x-[-8rem] "></div>
                  </div>
                </section>
              </>
            )}
          </>
        )}

        {!isLoading && !isIntro && (
          <>
            <div className="absolute flex h-full w-full items-center justify-center overflow-y-scroll text-white">
              <CountDown />
              <Replay />
              <EndGameMenu />
            </div>
            <div className="absolute flex h-full w-full flex-row items-end justify-center md:ml-10 md:items-center md:justify-start">
              <Leaderboard />
            </div>

            <div className="h-full w-full absolute flex justify-center items-center">
              <Results />
            </div>
            <div className="absolute flex h-full w-full ">
              <GameProgress />
            </div>
            <div className="absolute flex h-full w-full justify-start p-10 text-white ">
              <GameNav fullScreen={handle.enter} />
            </div>
          </>
        )}

        {props.children}
      </nav>
    </FullScreen>
  );
}

export default UI;
