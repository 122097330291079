import React, { useContext, useEffect, useState } from "react";
import { UIContext } from "../context/UIContext";
import { getTokenMetadata } from "../services/queries";
import { motion } from "framer-motion";

export default function Results() {
  const { lbRaceData, showReplay } = useContext(UIContext);

  const [outcome, setOutcome] = useState([]);

  const [outcomeTimes, setOutcomeTimes] = useState([]);

  useEffect(() => {
    if (lbRaceData.outcome) {
      // Map over the array and extract the time property of each object
      const times = lbRaceData.outcome.map((outcomeObj) => outcomeObj.time);
      setOutcomeTimes(times); // Save the extracted times into the outcomeTimes state
    }
  }, [lbRaceData]);

  useEffect(() => {
    async function getOutcome() {
      const newOutcome = [];
      if (lbRaceData.outcome?.length) {
        for (let i = 0; i < lbRaceData.outcome.length; i++) {
          const tokenInfo = await getTokenMetadata(
            lbRaceData.outcome[i].tokenId,
            lbRaceData.outcome[i].tokenFamily
          );
          newOutcome.push(tokenInfo.name);
        }
        setOutcome(newOutcome);
      }
    }

    getOutcome();
  }, [lbRaceData]);

  const [showResults, setShowResults] = useState(false);
  useEffect(() => {
    let timeout;
    if (showReplay) {
      timeout = setTimeout(() => setShowResults(true), 6000);
    }
    return () => clearTimeout(timeout);
  }, [showReplay]);

  function getOrdinalIndicator(number) {
    const suffixes = ["th", "st", "nd", "rd"];
    const lastTwoDigits = Math.abs(number) % 100;
    const lastDigit = lastTwoDigits % 10;
    const suffix =
      suffixes[
        lastDigit <= 3 && (lastTwoDigits < 11 || lastTwoDigits > 13)
          ? lastDigit
          : 0
      ];
    return number + suffix;
  }
  return (
    <>
      {showResults && (
        <div className="ml-0 md:ml-24 animate-in fade-in transition ease-in-out duration-1000 text-white w-full h-screen flex justify-center md:justify-start items-center pl-0 md:pl-24 mb-24">
          <ul className="flex flex-col space-y-2 w-3/4 px-5 md:px-0 md:w-1/4 text-center">
            <h1
              className="font-audiowide text-2xl sm:text-3xl md:text-4xl"
              style={{
                textShadow:
                  "2px 2px green, -2px -2px green, 2px -2px green, -2px 2px green",
              }}
            >
              Results
            </h1>
            {outcome.map((item, index) => (
              <motion.li
                key={item}
                initial={{ x: -100 }}
                animate={{ x: 0 }}
                transition={{ duration: 0.3, delay: index * 0.3 }}
              >
                <div
                  className={`p-1 rounded-md font-audiowide text-sm sm:text-base md:text-lg rounded-tl-2xl rounded-br-2xl ${
                    index === 0
                      ? "bg-yellow-400 bg-opacity-60"
                      : index === 1
                      ? "bg-gray-600 bg-opacity-60"
                      : index === 2
                      ? "bg-yellow-800 bg-opacity-60"
                      : "bg-blue-400 bg-opacity-30"
                  }`}
                >
                  <div className="flex justify-between items-center space-x-4 px-4">
                    <p className="font-audiowide text-xs sm:text-sm">
                      {getOrdinalIndicator(index + 1)}
                    </p>
                    <p className="text-white text-left font-audiowide text-xs sm:text-sm">
                      {item}
                    </p>
                    <p className="font-audiowide text-xs sm:text-sm">
                      {outcomeTimes[index].toFixed(2)}
                    </p>
                  </div>
                </div>
              </motion.li>
            ))}
          </ul>
        </div>
      )}
    </>
  );
}
