import {
  AdaptiveDpr,
  Environment,
  GizmoHelper,
  GizmoViewport,
  Html,
  Lightformer,
  OrbitControls,
  Preload,
  Sparkles,
  Stage,
  Stars,
  useProgress,
} from "@react-three/drei";
import { Canvas, extend, useFrame, useThree } from "@react-three/fiber";
import React, {
  useRef,
  useState,
  Suspense,
  useEffect,
  useCallback,
  useContext,
  useMemo,
} from "react";
import CameraControl from "./components/CameraControl/CameraControl";
import Ground from "./components/MainContainer";
import ObjectsContainer from "./components/ObjectsContainer";
import AudioManager from "../../../audio/audioManager";
import "./styles.scss";

import { getTokenMetadata, useCurrentRace } from "../../../../services/queries";
import { setAutocamera } from "../../../../store/mainSlice";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router";

import { TrackModel } from "./components/Environment/Track";
import { UIContext } from "../../../../context/UIContext";
import { TrackOnly } from "./components/Environment/TrackOnly";
import IntroScene from "./components/Intro/IntroScene";
import { SpaceDust } from "./components/Particles/spaceParticles";

import Tornado from "./components/Particles/Tornado";
import { ClonedBuildings } from "./components/Environment/SingleBuilding";
const FIT_GROUND = 1.55;
const ROAD_LENGTH = 80; // 800m

const skyboxOptions = [
  "/skybox/cybercity/",
  "/skybox/island/",
  "/skybox/jungle/",
  "/skybox/nebula/",
  "/skybox/underwater/",
  "/skybox/desert/",
  "/skybox/steamalley/",
  "/skybox/steamsky/",
];

const View3D = ({}) => {
  const { uuid } = useParams();
  const [progress, setProgress] = useState("idle");
  const [showSponsorPopup, setShowSponsorPopup] = useState(false);
  const navigate = useNavigate();

  const dispatch = useDispatch();
  const [raceData, setRaceData] = useState(undefined);
  const [tokenData, setTokenData] = useState(undefined);

  const orbitRef = useRef();
  const groundRef = useRef();
  const modelRef = useRef();
  const cameraControlRef = useRef();
  const progressRef = useRef();
  const [rank, setRank] = useState([0, 1, 2, 3, 4, 5, 6, 7]);
  const [isShowReplay, setIsShowReplay] = useState(false);
  const [startPosZ, setStartPosZ] = useState(0);
  const [scale, setScale] = useState(1);

  const {
    setShowEndGame,
    setlbTokenData,
    lbRank,
    setLBRaceData,
    loaderProgress,
    isIntro,
    setRaceStartLength,
  } = useContext(UIContext);

  const { data, isLoading, isSuccess, isFetching, refetch } =
    useCurrentRace(uuid);

  useEffect(() => {
    if (data && isSuccess) {
      // FOR PRODUCTION
      if (data.data.entrants.length < data.data.maxEntrants) {
        alert("Race is not filled yet!");
        window.location.href = `https://betnft.run/race/${uuid}?showResult=false`;
      }
      if (data.data.entrants.length > 0) {
        setRaceData(data.data);
        setLBRaceData(data.data);
      } else {
        alert("This race has no entrants.");
        window.location.href = `https://betnft.run/race/${uuid}?showResult=false`;
      }
    }
  }, [data, isSuccess]);

  useEffect(() => {
    if (raceData) {
      updateTokenInfo();
    }
  }, [raceData]);

  const [tokenDataUpdated, setTokenDataUpdated] = useState(false);

  const updateTokenInfo = useCallback(async () => {
    if (!raceData || tokenDataUpdated) return;

    let toUpdate = Array(8).fill(0);
    if (raceData.entrants) {
      for (let i = 0; i < raceData.entrants.length; i++) {
        let boxNumber = raceData.entrants[i].joinPos;
        let tokenInfo = await getTokenMetadata(
          raceData.entrants[i].tokenId,
          raceData.entrants[i].tokenFamily
        );

        toUpdate[boxNumber] = {
          ...tokenInfo,
          kennelName: raceData.entrants[i].kennel || "",
        };
      }
    }

    setTokenData(toUpdate);
    setlbTokenData(toUpdate);
    setTokenDataUpdated(true);
  }, [raceData, tokenDataUpdated]);

  useEffect(() => {
    updateTokenInfo();
  }, []);

  useEffect(() => {
    if (raceData) {
      const raceDist = (raceData.raceDistance / 800) * ROAD_LENGTH;
      const raceStart = ROAD_LENGTH - raceDist;

      setRaceStartLength(raceStart);
      setStartPosZ(raceStart);

      setScale((ROAD_LENGTH - raceStart) / raceData.raceDistance);

      console.log("DEBUG", raceData?.trackIndex);
    }
  }, [raceData]);

  const onHandleFinish = () => {
    setProgress("finished");
  };

  const onStart = () => {
    groundRef.current.onStart();
    modelRef.current.onStart();
    cameraControlRef.current.onStart();
    // progressRef.current.onStart();
  };

  const onHandleEnd = () => {
    setTimeout(() => {
      setShowEndGame(true);
      // console.log("SHOW END GAME");
      setShowSponsorPopup(true);
    }, 3000);
  };

  const onUpdateRanking = (rankingArr) => {
    setRank([...rankingArr]);
    lbRank.current = [...rankingArr];
  };

  const onCheckAutoCamera = (e) => {
    dispatch(setAutocamera(e.target.checked));
  };

  const colors = {
    malevolentIllusion: [
      "#FFD700",
      "#FFCC33",
      "#FFCC00",
      "#FFFF99",
      "#FFCC99",
      "#FFD700",
    ],
    sunnyRainbow: ["#ffffff"],
  };

  const memoizedClonedBuildings = useMemo(() => {
    // Place the logic for rendering <ClonedBuildings /> here
    if (
      !isLoading &&
      (raceData?.trackIndex === 0 || raceData?.trackIndex === undefined)
    ) {
      return <ClonedBuildings />;
    }
  }, [isLoading, raceData]);
  const memoizedGroundAndTrackComponents = useMemo(() => {
    return (
      <>
        {raceData && (
          <Ground
            ref={groundRef}
            raceData={raceData}
            SCALE={scale}
            startPosZ={startPosZ}
          />
        )}
        {!isLoading && (
          <>
            <TrackModel />
            <TrackOnly />
          </>
        )}
      </>
    );
  }, [raceData, isLoading, scale, startPosZ]);

  const memoizedEnvironment = useMemo(() => {
    return (
      <Environment
        background={false}
        files="/textures/potsdamer_platz_1k.hdr"
      />
    );
  }, []);

  return (
    <>
      <div className="view3d-container">
        <Canvas
          style={{
            width: "100vw",
            height: "100dvh",
          }}
          flat
          shadows={false}
          camera={{
            position: [0, 0, startPosZ + 3.55],
            zoom: 1,
            far: 100,
            near: 0.1,
            fov: 90,
          }}
        >
          <OrbitControls
            ref={orbitRef}
            enableDamping={true}
            enablePan={true}
            enableRotate={true}
            rotateSpeed={0.75}
            autoRotateSpeed={5}
            target={[0, 0, startPosZ + 1.55]}
          />
          <color attach="background" args={["#000000"]} />

          <Suspense>
            {raceData?.trackIndex !== undefined && (
              <Environment
                background={true}
                files={[
                  "cube_right.png",
                  "cube_left.png",
                  "cube_up.png",
                  "cube_down.png",
                  "cube_back.png",
                  "cube_front.png",
                ]}
                path={`/textures${
                  skyboxOptions[
                    raceData?.trackIndex === 8
                      ? Math.floor(Math.random() * skyboxOptions.length)
                      : raceData?.trackIndex
                  ]
                }`}
              />
            )}

            {raceData?.trackIndex === 3 && <SpaceDust count={80} />}

            {raceData?.trackIndex === 5 && <Tornado />}

            {memoizedEnvironment}
            {memoizedGroundAndTrackComponents}

            {raceData && tokenData && (
              <ObjectsContainer
                ref={modelRef}
                orbitControlRef={orbitRef}
                rank={rank}
                setIsShowReplay={setIsShowReplay}
                onUpdateRanking={onUpdateRanking}
                raceData={raceData}
                onHandleEnd={onHandleEnd}
                onHandleFinish={onHandleFinish}
                SCALE={scale}
                startPosZ={startPosZ}
                tokenData={tokenData}
              />
            )}
            {isIntro && <IntroScene />}
            {raceData && tokenData && loaderProgress === 100 && (
              <>
                <CameraControl
                  ref={cameraControlRef}
                  orbitControlRef={orbitRef}
                  raceData={raceData}
                  SCALE={scale}
                  startPosZ={startPosZ}
                  onHandleStart={() => {
                    onStart();
                  }}
                />
                <AudioManager />
              </>
            )}
          </Suspense>
        </Canvas>
      </div>
    </>
  );
};

export default View3D;
