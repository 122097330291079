import { Html } from "@react-three/drei";
import { useContext } from "react";
import { UIContext } from "../../../../../../context/UIContext";

function NameTag({ name }) {
  const { showNameTag, setShowNameTag } = useContext(UIContext);
  return showNameTag ? (
    <Html
      position={[0, 0.8, 0]}
      center
      distanceFactor={2.5}
      zIndexRange={[-1, 1]}
    >
      <div className="">
        <h1 className="w-[250px] rounded-br-xl rounded-tl-xl bg-[#3d485a] bg-opacity-50 px-1 text-center font-audiowide text-4xl italic text-white shadow-xl  ">
          {name}
        </h1>
      </div>
    </Html>
  ) : null;
}

export default NameTag;
