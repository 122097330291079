/* eslint-disable react-hooks/exhaustive-deps */
import { Physics } from "@react-three/cannon";
import { useFrame, useLoader, useThree } from "@react-three/fiber";
import {
  forwardRef,
  Suspense,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import {
  BoxGeometry,
  Color,
  Mesh,
  MeshBasicMaterial,
  TextureLoader,
  Vector3,
} from "three";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import { DRACOLoader } from "three/examples/jsm/loaders/DRACOLoader.js";
import gsap from "gsap";
import Model from "../Model";
import { useSelector } from "react-redux";
import { selectAutocamera } from "../../../../../../store/mainSlice";
import { useContext } from "react";
import { UIContext } from "../../../../../../context/UIContext";
import * as SkeletonUtils from "three/examples/jsm/utils/SkeletonUtils.js";
import GenerateMaterial from "../Model/ModelMaterials";
import { useControls } from "leva";
import { axiosInstance } from "../../../../../../config/config";
import { useParams } from "react-router";
import { getTokenMetadata } from "../../../../../../services/queries";
import { useMediaQuery } from "react-responsive";

const modelsData = [
  {
    id: 1,
    name: "Dog Base",
    src: "/models/Dogv2Compressed.glb",
  },
];

const texturesData = [
  {
    src: "/textures/T_Number_01_Dif.jpg",
  },
  {
    src: "/textures/T_Number_02_Dif.jpg",
  },
  {
    src: "/textures/T_Number_03_Dif.jpg",
  },
  {
    src: "/textures/T_Number_04_Dif.jpg",
  },
  {
    src: "/textures/T_Number_05_Dif.jpg",
  },
  {
    src: "/textures/T_Number_06_Dif.jpg",
  },
  {
    src: "/textures/T_Number_07_Dif.jpg",
  },
  {
    src: "/textures/T_Number_08_Dif.jpg",
  },
];

const initPos = new Vector3(0.965, 0, -1.55);
const distance = 0.595;

let currentPos = [];
let numOfEnd = 0;
const ROAD_LENGTH = 80;
const ROAD_END = 79;

const ObjectsContainer = forwardRef(
  (
    {
      onUpdateRanking,
      rank,
      raceData,
      onHandleEnd,
      onHandleFinish,
      SCALE,
      orbitControlRef,
      setIsShowReplay,
      startPosZ,
      tokenData,
    },
    ref
  ) => {
    const gameResult = raceData.entrants
      .sort((a, b) => a.time - b.time)
      .map((el) => el.joinPos);
    let gameRes = [...gameResult];
    const entrants = raceData.entrants;
    let fastTime;
    if (entrants.length > 0) {
      fastTime = entrants.sort((a, b) => a.time - b.time)[0].time;
      // do something with fastTime
    } else {
      // handle empty array case
    }

    const gltfs = useLoader(
      GLTFLoader,
      modelsData.map((i) => i.src),
      (loader) => {
        const dracoLoader = new DRACOLoader();
        dracoLoader.setDecoderPath(
          "https://www.gstatic.com/draco/versioned/decoders/1.5.5/"
        );
        loader.setDRACOLoader(dracoLoader);
      }
    );
    const textures = useLoader(
      TextureLoader,
      texturesData.map((i) => i.src)
    );

    const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1224px)" });

    const texture = new TextureLoader().load("/textures/T_Number_01_Dif.jpg");

    const numNormal = useLoader(TextureLoader, "/textures/T_Number_Nrm.jpg");
    const numRou = useLoader(TextureLoader, "/textures/T_Number_Rou.jpg");

    const isAutocamera = useSelector(selectAutocamera);

    const [models, setModels] = useState([]);
    const [isStart, setIsStart] = useState(false);

    const lastTarget = useRef(new Vector3());
    const { camera } = useThree();

    const [isReplay, setIsReplay] = useState(false);

    const { scene } = useThree();

    const {
      showReplay,
      setShowReplay,
      isLoading,
      setIsLoading,
      setGameProgress,
      setLoaderProgress,
      setStartedRace,
      isIntro,
      lbRaceData,
      entrantStats,
      setEntrantStats,
      entrantDataFetched,
      setentrantDataFetched,
      currentPosGlobal,
    } = useContext(UIContext);
    const textureLoader = new TextureLoader();
    const { uuid } = useParams();

    useImperativeHandle(ref, () => ({
      onStart: () => onStart(),
    }));
    // UNCOMMENT FOR CAMERA DEBUGGINGS
    // const { position, rotation, target } = useControls("Camera", {
    //   position: { value: { x: 0, y: 0, z: 0 }, step: 0.05 },
    //   rotation: { value: { x: 0, y: 0, z: 0 }, step: 0.05 },
    //   target: { value: { x: 0, y: 0, z: 0 }, step: 0.05 },
    // });
    // const prevPosition = useRef(position);

    const [cameraFirstPhase, setCameraFirstPhase] = useState(false);
    const [cameraSecondPhase, setCameraSecondPhase] = useState(false);
    const [cameraThirdPhase, setCameraThirdPhase] = useState(false);
    const [localTokenData, setLocalTokenData] = useState([]);

    useEffect(async () => {
      if (gltfs && numNormal && numRou && textures) {
        const clonedModels = [];

        const originalModel = gltfs[0].scene;
        let clonedCount = 0;
        for (let i = 0; i < tokenData.length; i++) {
          // TODO

          if (tokenData[i] !== 0) {
            const coatColorValue = tokenData[i].attributes?.find(
              (attr) =>
                attr.trait_type === "Coat Colour" ||
                attr.trait_type === "Coat Colour4"
            )?.value;

            clonedCount++; // increment the counter for each cloned model

            // update the loading progress as a percentage
            const progress = Math.floor(
              (clonedCount / raceData.entrants.length) * 100
            );

            // wait for 1 second before updating the progress
            await new Promise((resolve) => setTimeout(resolve, 100));

            setLoaderProgress(progress);

            const bloodlineValue = tokenData[i].attributes?.find(
              (attr) => attr.trait_type === "Bloodline"
            )?.value;

            const trimmedBloodlineValue = bloodlineValue.replace(/\s+/g, "");
            const trimmedCoatColorValue = coatColorValue.replace(/\s+/g, "");
            const tokenFamily = bloodlineValue.slice(0, 2);

            const clonedModel = SkeletonUtils.clone(originalModel);
            // Clone the material for each mesh in the cloned model
            clonedModel.traverse(function (child) {
              if (child.isMesh) {
                const clonedMaterial = child.material.clone(); // clone the material

                if (
                  tokenFamily === "G1" ||
                  tokenFamily === "G2" ||
                  tokenFamily === "G3" ||
                  tokenFamily === "G4"
                ) {
                  // set a random color value
                  if (clonedMaterial.name === "M_Dog_Cloth") {
                    clonedMaterial.map = textures[i]; // set the texture

                    clonedMaterial.normalMap = numNormal; // set the normal map
                    clonedMaterial.roughnessMap = numRou; // set the roughness map
                    child.material.metalness = 0;
                    child.material.roughness = 1;
                  } else if (clonedMaterial.name === "M_Dog_Skin") {
                    GenerateMaterial(
                      trimmedBloodlineValue,
                      textureLoader,
                      clonedMaterial,
                      trimmedCoatColorValue,
                      tokenFamily
                    );
                  }
                  child.material = clonedMaterial; // replace the material with the cloned material
                }
              }
            });
            clonedModel.userData = { gate: i }; // add userData to the cloned model
            clonedModels.push(clonedModel);
          }
        }

        setModels(clonedModels);
      }
    }, [gltfs, numNormal, numRou, textures, raceData.entrants.length]);

    useEffect(() => {
      const fetchEntrantData = async () => {
        const fetchedData = [];

        for (const entrant of lbRaceData.entrants) {
          try {
            if (!entrantDataFetched) {
              const response = await axiosInstance.get(
                `/tokens/${entrant.tokenFamily}/${entrant.tokenId}`
              );

              fetchedData.push(response.data);
            }
          } catch (error) {}
        }

        setEntrantStats(fetchedData);
        setentrantDataFetched(true);
      };

      if (!entrantDataFetched) {
        fetchEntrantData();
      }

      if (entrantDataFetched) {
        setTimeout(() => {
          if (entrantStats.length > 0 && models) {
            setIsLoading(false);
          }
        }, 2000);
      }
    }, [entrantStats, models, entrantDataFetched]);

    useEffect(() => {
      if (isReplay) {
        // BUG
        fastTime = lbRaceData.entrants.sort((a, b) => a.time - b.time)[0].time;
        const replayTime = (lbRaceData.raceTime - fastTime + 2) * 4;

        setTimeout(() => {
          onHandleEnd();
        }, replayTime * 1000);
      }
    }, [isReplay, lbRaceData]);

    const onStart = () => {
      if (!isLoading) {
        lastTarget.current = new Vector3(0, 0, 0);
        setTimeout(() => {
          setStartedRace(true);
          setIsStart(true);
        }, 4000);
      }
    };

    useEffect(() => {
      if (isStart) {
        if (isAutocamera) {
          orbitControlRef.current.enableRotate = false;
        } else {
          orbitControlRef.current.enableRotate = true;
        }
      } else {
        orbitControlRef.current.enableRotate = true;
        // orbitControlRef.current.enableRotate = true;
      }
    }, [isStart, isAutocamera]);

    useFrame(() => {
      if (isStart) {
        gameRes = [];
        currentPos = models
          .map((el) => {
            return {
              z: new Vector3().applyMatrix4(el.matrixWorld).z,
              gate: el.userData.gate,
            };
          })
          .sort((a, b) => b.z - a.z);

        if (
          isAutocamera &&
          currentPos[0].z > startPosZ + 5 &&
          currentPos[0].z < 70
        ) {
          if (currentPos[0].z > 5 && !cameraFirstPhase) {
            setCameraFirstPhase(true);

            gsap.to(camera.position, {
              x: -1.4,
              y: 2.7,
              z: camera.position.z + 1,
              duration: 1,
              ease: "ease.out",
            });
          }

          if (currentPos[0].z > 30 && !cameraSecondPhase) {
            setCameraSecondPhase(true);

            gsap.to(camera.position, {
              x: -4,
              y: 2,
              // z: camera.position.z - 2,
              duration: 2,
              ease: "ease.out",
            });
          }

          if (currentPos[0].z > 50 && !cameraThirdPhase) {
            setCameraThirdPhase(true);
            if (isTabletOrMobile) {
              gsap.to(camera.position, {
                x: -1.5,
                y: 2.5,
                z: camera.position.z - 7,
                duration: 2,
                ease: "ease.out",
              });
            } else {
              gsap.to(camera.position, {
                x: -1.5,
                y: 2.5,
                z: camera.position.z - 2,
                duration: 2,
                ease: "ease.out",
              });
            }
          }
        } else {
          orbitControlRef.current.autoRotate = false;
        }

        // UNCOMMENT
        // if (
        //   position.x !== prevPosition.current.x ||
        //   position.y !== prevPosition.current.y ||
        //   position.z !== prevPosition.current.z
        // ) {
        //   camera.position.set(
        //     camera.position.x + position.x - prevPosition.current.x,
        //     camera.position.y + position.y - prevPosition.current.y,
        //     camera.position.z + position.z - prevPosition.current.z
        //   );
        //   prevPosition.current = position;
        //   console.log(camera.position, currentPos[0].z);
        // }

        // console.log("currentPost", currentPos[0].z, "prev Pos", prevZPosition);

        setGameProgress(currentPos[0].z);
        currentPosGlobal.current = currentPos[0].z;

        if (currentPos[0].z > 70 && currentPos[0].z < ROAD_END) {
          orbitControlRef.current.setAzimuthalAngle(-Math.PI / 2);
          orbitControlRef.current.setPolarAngle((75 * Math.PI) / 180);
          if (isTabletOrMobile) {
            console.log("LAST PHASE1");
            gsap.to(camera.position, {
              x: -7,
              y: 3,
              duration: 2,
              ease: "none",
            });
          } else {
            gsap.to(camera.position, {
              x: -4,
              y: 3,
              duration: 2,
              ease: "none",
            });
          }
        }

        if (currentPos[0].z >= ROAD_END) {
          console.log("LAST PHASE2");
          gsap.to(camera.position, {
            x: -1.435,
            z: 88,
            y: 1,
            duration: 3,
            ease: "none",
            onComplete: () => {
              const raceEnd = scene.getObjectByName("Race_End");
              if (raceEnd) {
                raceEnd.visible = false;
              }

              const geo = new BoxGeometry(5.41, 0.01, 0.1);
              const mesh = new Mesh(
                geo,
                new MeshBasicMaterial({ color: new Color(255, 255, 255) })
              );
              mesh.position.set(-1.135, 0, 79.2);
              scene.add(mesh);

              setShowReplay(true);
              setIsShowReplay(true);

              gsap.to(orbitControlRef.current.target, {
                x: 0.965 - 0.595 * 4,
                z: ROAD_END,
                y: 0,
                duration: 1,
                ease: "none",
                onComplete: () => {
                  if (isTabletOrMobile) {
                    gsap.to(camera.position, {
                      x: -7,
                      z: ROAD_END,
                      y: 3,
                      duration: 1,
                      ease: "none",
                      onComplete: () => {
                        setIsReplay(true);
                      },
                    });
                  } else {
                    gsap.to(camera.position, {
                      x: -4,
                      z: ROAD_END,
                      y: 2,
                      duration: 1,
                      ease: "none",
                      onComplete: () => {
                        setIsReplay(true);
                      },
                    });
                  }
                },
              });
            },
          });
        }

        // Count the number of objects whose z position is greater than or equal to ROAD_END.

        numOfEnd = currentPos.filter((el) => el.z >= ROAD_END).length;

        // Add the results of the completed games up to the number of objects at the end of the road to the game result array.
        gameRes.push(...gameResult.slice(0, numOfEnd));

        // Add the gate of each remaining object to the game result array.
        gameRes.push(
          ...currentPos.map((el) => el.gate).slice(numOfEnd, currentPos.length)
        );

        // Update the ranking of the game.

        onUpdateRanking(gameRes);

        // Check if all objects have reached the end of the road.
        if (
          models.filter(
            (el) => new Vector3().applyMatrix4(el.matrixWorld).z < ROAD_END
          ).length === 0
        ) {
          // If all objects have reached the end, finish the game and update the ranking.
          onHandleFinish();
          onUpdateRanking(gameResult);
          setIsStart(false);
        }
      }
    });

    return (
      <>
        <Suspense fallback={null}>
          <Physics gravity={[0, 0, 0]}>
            <group>
              {models &&
                models.map((item, idx) => {
                  // console.log("token data", tokenData);
                  const nonZeroIndexes = tokenData
                    .map((token, index) => (token !== 0 ? index : null))
                    .filter((index) => index !== null);
                  const nonZeroIndex = nonZeroIndexes[idx];
                  const name = tokenData[nonZeroIndex]?.name || "";

                  return (
                    <Model
                      key={idx}
                      orbitControlRef={orbitControlRef}
                      rank={rank}
                      idx={idx}
                      animations={gltfs[0].animations}
                      model={item}
                      modelObj={item}
                      materials={item.materials}
                      isStart={isStart}
                      isReplay={isReplay}
                      initPos={
                        new Vector3(
                          initPos.x - +nonZeroIndex * distance,
                          0,
                          startPosZ
                        )
                      }
                      raceDist={raceData?.raceDists?.[idx] || 0}
                      raceSpeed={raceData?.raceSpeeds?.[idx] || 0}
                      raceReplayTime={fastTime - 1}
                      SCALE={SCALE}
                      startPosZ={startPosZ}
                      name={name}
                    />
                  );
                })}
            </group>
          </Physics>
        </Suspense>
      </>
    );
  }
);

export default ObjectsContainer;
