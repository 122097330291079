import React, { useContext, useEffect, useState } from "react";
import { UIContext } from "../context/UIContext";

export default function Replay() {
  const { showReplay, setShowReplay } = useContext(UIContext);
  const [hideReplay, setHideReplay] = useState(false);

  useEffect(() => {
    if (showReplay) {
      const timer = setTimeout(() => {
        setHideReplay(true);
      }, 5000);
      return () => clearTimeout(timer);
    }
  }, [showReplay]);

  return (
    <div className="text-center text-6xl font-audiowide text-amber-400">
      {!hideReplay && showReplay && (
        <div className="inline-block animate-replay opacity-100">REPLAY</div>
      )}
    </div>
  );
}
