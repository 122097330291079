import { useContext, useEffect, useState } from "react";
import spinner from "./Elipse.svg";
import { UIContext } from "../../context/UIContext";
export default function Loader() {
  const { loaderProgress } = useContext(UIContext);

  return (
    <div className="fixed top-0 left-0 right-0 bottom-0 flex items-center justify-center bg-black">
      <div className="flex flex-col flex-y-5 items-center">
        <h2
          className="mt-2 mb-4 text-4xl lg:text-5xl font-bold font-heading font-audiowide animate-pulse"
          style={{
            textShadow:
              "2px 2px green, -2px -2px green, 2px -2px green, -2px 2px green",
          }}
        >
          BetNFT
        </h2>

        <div
          className="flex flex-col justify-center items-center mt-4 animate-pulse"
          style={{
            textShadow:
              "2px 2px green, -2px -2px green, 2px -2px green, -2px 2px green",
          }}
        >
          <p className="font-audiowide text-lg text-white">{loaderProgress}%</p>
          <img src={spinner} alt="loading" className="h-24" />
        </div>
        <p className="font-audiowide mb-2  text-white  text-sm animate-pulse">
          Touch or Click for Sound
        </p>
      </div>
    </div>
  );
}
