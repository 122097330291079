import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { UIContext } from "../context/UIContext";

export default function EndGameMenu() {
  const { showEndGame } = useContext(UIContext);
  const { uuid } = useParams();
  const [showFlashText, setShowFlashText] = useState(false);

  useEffect(() => {
    if (showEndGame) {
      const timeoutId = setTimeout(() => {
        setShowFlashText(true);
        setTimeout(() => {
          window.location.href = `https://betnft.run/race/${uuid}?showResult=true`;
        }, 4000);
      }, 7000);

      return () => clearTimeout(timeoutId);
    }
  }, [showEndGame, uuid]);

  return (
    <>
      {showEndGame && (
        <section className="animate-in fade-in transition ease-in-out duration-1000 py-20 bg-black w-full h-full z-[999] flex justify-center items-center overflow-y-auto">
          <section id="sponsor">
            <div className="container px-4 mx-auto">
              <div className="max-w-3xl mx-auto text-center">
                <h2
                  className="mt-2 mb-4 text-4xl lg:text-5xl font-bold font-heading font-audiowide"
                  style={{
                    textShadow:
                      "2px 2px green, -2px -2px green, 2px -2px green, -2px 2px green",
                  }}
                >
                  BetNFT
                </h2>

                <div className="w-full h-full flex flex-col justify-center">
                  <span
                    className="text-lg text-gray-200 uppercase font-audiowide"
                    style={{
                      textShadow:
                        "1px 1px green, -1px -1px green, 1px -1px green, -1px 1px green",
                    }}
                  >
                    This race is proudly brought to you by
                  </span>
                  <img
                    src="/Sponsorship.png"
                    className="w-1/2 h-1/2 self-center"
                    alt=""
                  ></img>
                </div>

                <p className="mb-6 mobile-landscape:text-sm portrait:text-lg leading-loose font-audiowide text-white animate-pulse">
                  Hold on for detailed race results...
                </p>

                <div className="flex flex-wrap justify-center">
                  <a
                    href={`https://betnft.run/race`}
                    className="inline-block w-full sm:w-auto px-6 py-2 mb-3 sm:mb-0 sm:mr-4 text-sm text-white font-bold leading-loose border-2 border-green-500 font-audiowide hover:border-green-700 rounded transition duration-200"
                  >
                    HOME
                  </a>
                  <a
                    href={`/race/${uuid}`}
                    className="inline-block w-full sm:w-auto px-6 py-2 mb-3 sm:mb-0 sm:mr-4 text-sm text-white font-bold leading-loose border-2 border-green-500 font-audiowide hover:border-green-700 rounded transition duration-200"
                  >
                    REPLAY
                  </a>
                </div>
              </div>
            </div>
          </section>
        </section>
      )}
    </>
  );
}
