import { useFrame, useLoader, useThree } from "@react-three/fiber";
import {
  forwardRef,
  Suspense,
  useEffect,
  useImperativeHandle,
  useRef,
} from "react";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import { gsap } from "gsap";
import { Reflector } from "three/addons/objects/Reflector.js";
import {
  Box3,
  CircleGeometry,
  Color,
  MeshBasicMaterial,
  PlaneGeometry,
  TextureLoader,
} from "three";
import { Stage } from "@react-three/drei";

const ROAD_LENGTH = 80;
const Ground = forwardRef(({ raceData, SCALE, startPosZ }, ref) => {
  const { scene } = useThree();
  useImperativeHandle(ref, () => ({
    onStart: () => onStart(),
  }));

  const ground = useLoader(GLTFLoader, "/newModels/Track25042023.glb");
  const reflectorRef = useRef();

  const trackTexture = `/textures/02_BaseColor.png`;

  const textureLoader = new TextureLoader();

  // load the textures using the texture loader

  useEffect(() => {
    if (raceData.raceDistance < 800 && raceData.raceDistance % 100 === 0) {
      const startLine = scene.getObjectByName(`m${raceData.raceDistance}`);
      if (startLine) {
        startLine.visible = false;
      }
    }
  }, [raceData]);

  useEffect(() => {
    const ball = scene.getObjectByName("Ball_Follower");
    if (ball) {
      ball.position.set(ball.position.x, ball.position.y, startPosZ);
    }

    const Race_Start = scene.getObjectByName("Race_Start");
    if (Race_Start) {
      Race_Start.position.set(ball.position.x, ball.position.y, startPosZ);
    }

    const Panel_Name_Race = scene.getObjectByName("Panel_Name_Race");
    if (Panel_Name_Race) {
      Panel_Name_Race.position.set(ball.position.x, ball.position.y, startPosZ);
    }

    for (let i = 0; i < 8; i++) {
      const door = scene.getObjectByName(`Door_0${i + 1}`);
      if (door) {
        door.position.set(ball.position.x, ball.position.y, startPosZ);
      }
    }

    const Separators = scene.getObjectByName("Separators");
    if (Separators) {
      Separators.position.set(ball.position.x, ball.position.y, startPosZ);
    }
  }, [startPosZ]);

  const geometry = new PlaneGeometry(0, 0);

  useEffect(() => {
    if (ground.scene) {
      const boxReflector = new Box3();
      ground.scene.traverse((object) => {
        if (object.name.includes("Track")) {
          object.visible = false;
        }
        if (object.name.includes("Building")) {
          object.visible = false;
        }
        if (object.name.includes("Terrain")) {
          object.visible = false;
        }
        if (object.name.includes("Panel_Name_Race")) {
          object.children[0].material.color = new Color("#17FD49");
          object.children[1].material.color = new Color(8, 8, 8);
        }
      });
    }
  }, [ground, geometry, scene]);

  function onStart() {
    const doors = [];
    const ballFollower = [];
    ground.scene.traverse((o) => {
      if (o.name.includes("Door")) {
        doors.push(o);
      }
      if (o.name.toLowerCase().includes("ball")) {
        ballFollower.push(o);
      }
    });
    setTimeout(() => {
      moveDoor(doors);
    }, 4000);
    setTimeout(() => {
      moveBallFollower(ballFollower);
    }, 4000);
  }

  function moveDoor(doors) {
    for (const door of doors) {
      setTimeout(() => {
        door.visible = false;
      }, 300); // Delay of 1 second (1000 milliseconds)
      gsap.to(door.position, { y: -0.97, duration: 0.4, ease: "power3.inOut" });
    }
  }

  function moveBallFollower(ballFollower) {
    for (const ball of ballFollower) {
      gsap.to(ball.position, {
        z: ROAD_LENGTH,
        duration: raceData.raceTime * 0.9,
        ease: "none",
        onComplete: () => {
          gsap.to(ball.position, {
            z: 100,
            duration: 10,
            ease: "none",
          });
        },
      });
    }
  }

  return (
    <Suspense fallback={null}>
      <primitive object={ground.scene} />
    </Suspense>
  );
});

export default Ground;
