import React, { useRef, useMemo, useState, useEffect } from "react";
import { extend, useFrame, useThree } from "@react-three/fiber";
import { ShaderMaterial, Vector2 } from "three";
import * as THREE from "three";

// Fragment Shader
const fragmentShader = `
varying float vStripes;
varying float vOpacity;

void main() {
    gl_FragColor = vec4(vec3(0.6, 0.4, 0.2) * vStripes * 10., vOpacity);
}
`;

// Vertex Shader
const vertexShader = `
#define PI 3.14159265359

uniform float u_time;
uniform float u_height;
uniform float u_density;
uniform float u_curl;
uniform vec2 u_wind;

varying float vStripes;
varying float vOpacity;

vec2 random2(vec2 p) {
    return fract(sin(vec2(dot(p,vec2(127.1,311.7)),dot(p,vec2(269.5,183.3))))*43758.5453);
  }

  float voronoi(vec2 _uv, float time){
    vec2 i_uv = floor(_uv);
    vec2 f_uv = fract(_uv);
    float min_dist = 2.;
    for (int j = -1; j <= 1; j++) {
      for (int i = -1; i <= 1; i++) {
        vec2 tile_offset = vec2(float(i), float(j));
        vec2 cell_center = .5 + .5 * sin(time * .5 + PI * 2. * random2(i_uv + tile_offset));
        float dist = length(tile_offset + cell_center - f_uv);
        min_dist = min(min_dist, dist);
      }
    }
    return pow(min_dist, 2.);
  }

  mat2 rotate2d(float angle) {
    return mat2(cos(angle), -sin(angle), sin(angle), cos(angle));
  }

  float cubic_pulse_shape(float center, float width, float x) {
    x = abs(x - center);
    if (x > width) return 0.;
    x /= width;
    return 1. - x * x * (3. - 2. * x);
  }

  float cone_shape(float x) {
    return .5 * cos(x * 3.1 + 2.6) + .5 + exp(-12. * x);
  }

void main() {
    vec3 pos = position;
    float y_factor = uv.x;

    // Less visibile on right and back
    float vertical_transparency = pow(3. * y_factor * (1. - y_factor), 2.5);
    float back_transparency = pow(pos.x + 1., 2.) * pow(pos.z + 1., 2.);
    vOpacity = vertical_transparency * back_transparency;

    // Spiral stuuf over the cyllinder
    vec2 voronoi_point = vec2(atan(pos.x, pos.z) - pos.y * u_curl, pos.y - u_time);
    float bumps = voronoi(u_density * voronoi_point, u_time);
    vec3 pos_no_bump = pos;
    pos -= (normal * .2 * bumps);
    vStripes = length(pos_no_bump - pos);

    // Shaping the cyllinder
    float cone = cone_shape(y_factor);
    pos.x *= cone;
    pos.z *= cone;
    pos.y *= u_height;

    // Add slight constant rotation for central part
    vec2 wind = vec2(.04, 0.);
    wind = rotate2d(u_time * 2.) * wind;
    pos += (vec3(wind.x, 0., wind.y) * (1. - cone));

    // Make the central part to follow the mouse
    wind += u_wind;
    pos += vec3(wind.x, 0., wind.y) * cubic_pulse_shape(.35, .8, y_factor);

    gl_Position = projectionMatrix * modelViewMatrix * vec4(pos, 1.);
}
`;

// Extending THREE with custom shader material
class CustomShaderMaterial extends ShaderMaterial {
  constructor() {
    super({
      uniforms: {
        u_time: { value: 0 },
        u_height: { value: 1.1 },
        u_density: { value: 2.5 },
        u_curl: { value: 12 },
        u_wind: { value: new Vector2(0, 0) },
      },
      vertexShader,
      fragmentShader,
      side: THREE.DoubleSide,
      transparent: true,
    });
  }
}

extend({ CustomShaderMaterial });

// Tornado Component
function Tornado() {
  const meshRefs = useRef([]);
  const materialRefs = useRef([]);
  const { clock } = useThree();

  // Randomize initial positions, scales, and movement speeds for the three tornadoes
  const tornadoes = useMemo(() => {
    return [...Array(3)].map(() => {
      // Generate a random number between 0 and 1
      const randomFactor = Math.random();

      // Calculate x position
      let xPosition;
      if (randomFactor < 0.5) {
        // If randomFactor is less than 0.5, generate x between -40 and -20
        xPosition = -20 - Math.random() * 20;
      } else {
        // If randomFactor is 0.5 or more, generate x between 20 and 40
        xPosition = 20 + Math.random() * 20;
      }

      return {
        position: new THREE.Vector3(xPosition, 0, 150 + Math.random() * 50),
        scale: new THREE.Vector3(1, 1, 1).multiplyScalar(
          15 + Math.random() * 10
        ),
        moveSpeed: 0.01 + Math.random() * 0.05,
      };
    });
  }, []);

  useFrame(() => {
    const elapsedTime = clock.getElapsedTime();

    meshRefs.current.forEach((mesh, index) => {
      const tornado = tornadoes[index];

      if (materialRefs.current[index]) {
        materialRefs.current[index].uniforms.u_time.value = elapsedTime;
      }

      // Update position for movement
      let newPos = mesh.position.clone();

      newPos.z -= tornado.moveSpeed; // Moving in the negative Z direction

      // Reset the Z position when it goes beyond the threshold
      if (newPos.z < -50) {
        newPos.z = 150; // Reset to starting Z position
      }

      mesh.position.set(newPos.x, newPos.y, newPos.z);
    });
  });

  const geometry = useMemo(() => {
    const curve = new THREE.LineCurve3(
      new THREE.Vector3(0, 0, 0),
      new THREE.Vector3(0, 1, 0)
    );
    return new THREE.TubeGeometry(curve, 512, 0.55, 512, false);
  }, []);

  return (
    <>
      {tornadoes.map((tornado, index) => (
        <mesh
          key={index}
          ref={(ref) => (meshRefs.current[index] = ref)}
          position={tornado.position}
          rotation={[0, -0.4 * Math.PI, 0]}
          scale={tornado.scale}
          geometry={geometry}
        >
          <customShaderMaterial
            ref={(ref) => (materialRefs.current[index] = ref)}
            attach="material"
          />
        </mesh>
      ))}
    </>
  );
}

export default Tornado;
