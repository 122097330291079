import React, { useContext } from "react";
import { MdLocationPin } from "react-icons/md";
import { AiFillFlag } from "react-icons/ai";
import { UIContext } from "../context/UIContext";
import { useSelector } from "react-redux";
import { selectAutocamera, setAutocamera } from "../store/mainSlice";
import { useDispatch } from "react-redux";
import { GiSpeakerOff, GiSpeaker } from "react-icons/gi";
import { BsArrowsFullscreen } from "react-icons/bs";

export default function GameNav({ fullScreen }) {
  const { showNameTag, setShowNameTag, lbRaceData, mute, setMute } =
    useContext(UIContext);

  const isAutocamera = useSelector(selectAutocamera);
  const dispatch = useDispatch();

  const onCheckAutoCamera = (e) => {
    dispatch(setAutocamera(e.target.checked));
  };

  const handleShowNametag = (event) => {
    setShowNameTag(event.target.checked);
  };

  return (
    <>
      <main className="flex w-full flex-col justify-between  md:flex-row">
        <section className=" mt-8 flex justify-center md:mt-0  md:w-full md:justify-start">
          <div className="flex flex-col">
            <div className="flex items-center space-x-2">
              <AiFillFlag />
              <p className="text-md font-audiowide md:text-3xl">
                {lbRaceData?.raceName}
              </p>
            </div>

            <div className="mb-2 flex items-center space-x-2">
              <MdLocationPin />
              <p className="font-audiowide text-xs text-green-400 md:text-lg">
                {lbRaceData?.raceLocation} - {lbRaceData?.raceDistance}M -{" "}
                {lbRaceData?.raceClass}
              </p>
            </div>
          </div>
        </section>

        <section className="flex w-full flex-row justify-between items-center  space-x-5 self-start md:justify-end md:space-x-5">
          <div className="items flex space-x-2">
            <label
              className="inline-block pl-[0.15rem] font-audiowide text-sm hover:cursor-pointer md:text-lg"
              htmlFor="switchNameTag"
            >
              Show Name Tag
            </label>
            <input
              className="mr-2 mt-[0.4rem] h-3.5 w-8 appearance-none rounded-[0.4375rem] bg-green-200 before:pointer-events-none before:absolute before:h-3.5 before:w-3.5 before:rounded-full before:bg-transparent before:content-[''] after:absolute after:z-[2] after:-mt-[0.1875rem] after:h-5 after:w-5 after:rounded-full after:border-none after:bg-neutral-100 after:shadow-[0_0px_3px_0_rgb(0_0_0_/_7%),_0_2px_2px_0_rgb(0_0_0_/_4%)] after:transition-[background-color_0.2s,transform_0.2s] after:content-[''] checked:bg-green-500 checked:after:absolute checked:after:z-[2] checked:after:-mt-[3px] checked:after:ml-[1.0625rem] checked:after:h-5 checked:after:w-5 checked:after:rounded-full checked:after:border-none checked:after:bg-green-500 checked:after:shadow-[0_3px_1px_-2px_rgba(0,0,0,0.2),_0_2px_2px_0_rgba(0,0,0,0.14),_0_1px_5px_0_rgba(0,0,0,0.12)] checked:after:transition-[background-color_0.2s,transform_0.2s] checked:after:content-[''] hover:cursor-pointer focus:outline-none focus:ring-0 focus:before:scale-100 focus:before:opacity-[0.12] focus:before:shadow-[3px_-1px_0px_13px_rgba(0,0,0,0.6)] focus:before:transition-[box-shadow_0.2s,transform_0.2s] focus:after:absolute focus:after:z-[1] focus:after:block focus:after:h-5 focus:after:w-5 focus:after:rounded-full focus:after:content-[''] checked:focus:border-green-500 checked:focus:bg-green-500 checked:focus:before:ml-[1.0625rem] checked:focus:before:scale-100 checked:focus:before:shadow-[3px_-1px_0px_13px_#3b71ca] checked:focus:before:transition-[box-shadow_0.2s,transform_0.2s] dark:bg-neutral-600 dark:after:bg-neutral-400 dark:checked:bg-green-500 dark:checked:after:bg-green-500 dark:focus:before:shadow-[3px_-1px_0px_13px_rgba(255,255,255,0.4)] dark:checked:focus:before:shadow-[3px_-1px_0px_13px_#3b71ca]"
              type="checkbox"
              role="switch"
              name="switchNameTag"
              id="switchNameTag"
              onChange={handleShowNametag}
              checked={showNameTag}
            />
          </div>

          <div className="flex  space-x-2">
            <label
              className="inline-block pl-[0.15rem] font-audiowide text-sm hover:cursor-pointer md:text-lg"
              htmlFor="switchCamera"
            >
              Auto Camera
            </label>
            <input
              className="mr-2 mt-[0.4rem] h-3.5 w-8 appearance-none rounded-[0.4375rem] bg-green-200 before:pointer-events-none before:absolute before:h-3.5 before:w-3.5 before:rounded-full before:bg-transparent before:content-[''] after:absolute after:z-[2] after:-mt-[0.1875rem] after:h-5 after:w-5 after:rounded-full after:border-none after:bg-neutral-100 after:shadow-[0_0px_3px_0_rgb(0_0_0_/_7%),_0_2px_2px_0_rgb(0_0_0_/_4%)] after:transition-[background-color_0.2s,transform_0.2s] after:content-[''] checked:bg-green-500 checked:after:absolute checked:after:z-[2] checked:after:-mt-[3px] checked:after:ml-[1.0625rem] checked:after:h-5 checked:after:w-5 checked:after:rounded-full checked:after:border-none checked:after:bg-green-500 checked:after:shadow-[0_3px_1px_-2px_rgba(0,0,0,0.2),_0_2px_2px_0_rgba(0,0,0,0.14),_0_1px_5px_0_rgba(0,0,0,0.12)] checked:after:transition-[background-color_0.2s,transform_0.2s] checked:after:content-[''] hover:cursor-pointer focus:outline-none focus:ring-0 focus:before:scale-100 focus:before:opacity-[0.12] focus:before:shadow-[3px_-1px_0px_13px_rgba(0,0,0,0.6)] focus:before:transition-[box-shadow_0.2s,transform_0.2s] focus:after:absolute focus:after:z-[1] focus:after:block focus:after:h-5 focus:after:w-5 focus:after:rounded-full focus:after:content-[''] checked:focus:border-green-500 checked:focus:bg-green-500 checked:focus:before:ml-[1.0625rem] checked:focus:before:scale-100 checked:focus:before:shadow-[3px_-1px_0px_13px_#3b71ca] checked:focus:before:transition-[box-shadow_0.2s,transform_0.2s] dark:bg-neutral-600 dark:after:bg-neutral-400 dark:checked:bg-green-500 dark:checked:after:bg-green-500 dark:focus:before:shadow-[3px_-1px_0px_13px_rgba(255,255,255,0.4)] dark:checked:focus:before:shadow-[3px_-1px_0px_13px_#3b71ca]"
              type="checkbox"
              role="switch"
              id="switchCamera"
              name="switchCamera"
              checked={isAutocamera}
              onChange={(e) => {
                onCheckAutoCamera(e);
              }}
            />
          </div>
        </section>
        <div className="absolute right-0 top-0 p-5 md:p-20 flex items-center gap-2">
          <button className="z-50 hover:cursor-pointer">
            {mute ? (
              <GiSpeakerOff
                size={40}
                onClick={() => {
                  setMute((prev) => !prev);
                }}
              />
            ) : (
              <GiSpeaker
                size={40}
                onClick={() => {
                  setMute((prev) => !prev);
                }}
              />
            )}
          </button>

          <BsArrowsFullscreen
            className="z-50 text-white hover:cursor-pointer"
            size={25}
            onClick={fullScreen}
          />
        </div>
      </main>
    </>
  );
}
