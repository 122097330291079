import React, { useContext, useEffect, useRef, useState } from "react";
import { UIContext } from "../context/UIContext";

export default function CountDown() {
  const { showCountDown, setShowCountdown, setStartTimer, lbRaceData } =
    useContext(UIContext);
  const [count, setCount] = useState(5);
  const [startCountDown, setStartCountDown] = useState(false);

  useEffect(() => {
    if (lbRaceData?.startTime) {
      const timestamp = lbRaceData?.startTime;

      const interval = setInterval(() => {
        const now = new Date().getTime();
        const distance = timestamp - now;
        if (distance <= 5000) {
          setStartCountDown(true);
        }
        if (distance < 0) {
          clearInterval(interval);
          return;
        }

        return () => clearInterval(interval);
      });
    }
  }, []);

  useEffect(() => {
    if (count > 0 && showCountDown && startCountDown) {
      setTimeout(() => {
        setCount(count - 1);
        setStartTimer(count - 1);
      }, 1000);
    } else if (count === 0) {
      setTimeout(() => {
        setShowCountdown(false);
      }, 2000);
      // perform any action when countdown reaches 0
    } else {
      setCount(5); // reset count when showCountDown becomes false
    }
  }, [count, showCountDown, setShowCountdown, startCountDown]);

  return showCountDown && startCountDown ? (
    <div className="text-center font-audiowide text-6xl text-amber-400">
      {count === 0 ? "GO!" : count}
    </div>
  ) : null;
}
