import React, { useState, useEffect, useContext, useRef } from "react";
import { GiTrophy } from "react-icons/gi";
import { motion, AnimatePresence } from "framer-motion";

import { FiChevronDown, FiChevronUp, FiAward } from "react-icons/fi";
import { UIContext } from "../context/UIContext";
import { getTokenMetadata } from "../services/queries";
import { useMediaQuery } from "react-responsive";
const CARD_IMAGE_BASE_URL = "/textures/ResultNumbers/Result";
function Leaderboard() {
  const {
    lbRank,
    lbTokenData,
    lbRaceData,
    finishedNames,
    showReplay,
    currentPosGlobal,
  } = useContext(UIContext);

  const cardRef = useRef([]);

  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1224px)" });
  const [isOpen, setIsOpen] = useState(!isTabletOrMobile);
  const [outcome, setOutcome] = useState([]);
  const toggleDrawer = () => {
    setIsOpen((prevState) => !prevState);
  };

  useEffect(() => {
    async function getOutcome() {
      const newOutcome = [];
      if (lbRaceData.outcome?.length) {
        for (let i = 0; i < lbRaceData.outcome.length; i++) {
          const tokenInfo = await getTokenMetadata(
            lbRaceData.outcome[i].tokenId,
            lbRaceData.outcome[i].tokenFamily
          );
          newOutcome.push(tokenInfo.name);
        }
        setOutcome(newOutcome);
      }
    }

    getOutcome();
  }, [lbRaceData]);

  useEffect(() => {
    if (lbRank.current && lbTokenData && lbRaceData) {
      const nonZeroTokens = lbTokenData.reduce(
        (acc, token, index) => {
          if (token !== 0) {
            acc.filteredData.push(token);
            acc.indices.push(index);
          }
          return acc;
        },
        { filteredData: [], indices: [] }
      );

      const newCards = nonZeroTokens.filteredData.map((token, index) => {
        const cardRank = lbRank.current.findIndex((el) => el === index);

        const imageUrl = `${CARD_IMAGE_BASE_URL}${
          nonZeroTokens.indices[index] + 1
        }.png`;

        return { rank: cardRank, id: index, token, imageUrl };
      });

      newCards.sort((a, b) => a.rank - b.rank);

      cardRef.current = newCards;
    }

    if (currentPosGlobal.current >= 78) {
      const rearrangedCards = [];

      for (const cardName of outcome) {
        const card = cardRef.current.find((card) => {
          return card.token.name === cardName; // Assuming you have a 'name' property in card object
        });

        if (card) {
          rearrangedCards.push(card);
        }
      }

      cardRef.current = rearrangedCards;
    }
  }, [lbTokenData, lbRank.current, outcome, currentPosGlobal]);

  return (
    <>
      {!showReplay && (
        <div
          id="parentDiv"
          className="bottom-[20px] mb-24 mt-0 flex w-[200px] flex-col space-y-4 rounded-md border-2 border-green-500 bg-[#3d485a] bg-opacity-50 pb-8 md:mt-20"
          style={{ overflowY: "scroll", position: "relative" }}
        >
          <div className="flex items-center justify-between rounded-t-md bg-[#2d3748] bg-opacity-0 px-3 py-1">
            <div className="flex items-center space-x-1">
              <GiTrophy className="text-2xl text-yellow-400" />
              <div className="font-audiowide text-sm font-medium text-white">
                Leaderboard
              </div>
            </div>
            <button
              onClick={() => setIsOpen(!isOpen)}
              className="z-[25] focus:outline-none"
            >
              {isOpen ? (
                <FiChevronUp className="text-xl text-white" />
              ) : (
                <FiChevronDown className="text-xl text-white" />
              )}
            </button>
          </div>

          {isOpen && (
            <AnimatePresence>
              <motion.div
                key={cardRef.current}
                initial={{ opacity: 0, height: 0 }}
                animate={{ opacity: 1, height: "auto" }}
                exit={{ opacity: 0, height: 0 }}
                transition={{ duration: 0.2 }}
              >
                {cardRef.current.map((card, index) => {
                  const { imageUrl, token } = card;
                  const tokenWithImageUrl = { ...token, imageUrl };

                  const isFinished = finishedNames.includes(
                    tokenWithImageUrl?.name
                  );

                  let backgroundGradient = "";
                  switch (index) {
                    case 0:
                      backgroundGradient =
                        "linear-gradient(to right, rgba(255, 215, 0, 0.5), rgba(255, 165, 0, 0.8))";
                      break;
                    case 1:
                      backgroundGradient =
                        "linear-gradient(to right, rgba(192, 192, 192, 0.5), rgba(169, 169, 169, 0.8))";
                      break;
                    case 2:
                      backgroundGradient =
                        "linear-gradient(to right, rgba(205, 127, 50, 0.5), rgba(139, 69, 19, 0.8))";
                      break;
                    default:
                      backgroundGradient =
                        "linear-gradient(to right, rgba(178, 216, 235, 0.5), rgba(123, 163, 168, 0.3))";
                      break;
                  }

                  return (
                    <motion.div
                      key={card.id}
                      className="mb-4 h-[15px] sm:h-[8px] lg:mb-5 xl:h-[15px]"
                      initial={{ opacity: 0, y: -40 }}
                      animate={{ opacity: 1, y: 0 }}
                      exit={{ opacity: 0, y: 40 }}
                      transition={{ duration: 0.2 }}
                      layout
                    >
                      <div className="flex items-center space-x-8 p-4">
                        <img
                          src={imageUrl}
                          className="h-6 w-6 sm:h-5 sm:w-5 lg:h-6 lg:w-6"
                          alt=""
                        />
                        <div
                          className="absolute mt-1 flex w-[150px] flex-col justify-start rounded-tl-md rounded-br-md"
                          style={{
                            background: isFinished ? backgroundGradient : "",
                            backgroundSize: "300% 100%",
                          }}
                        >
                          <div className="flex items-center space-x-1">
                            <div className="flex flex-col">
                              <div className="font-audiowide text-xs italic text-white sm:text-xxs lg:text-sm">
                                {tokenWithImageUrl?.name}
                              </div>
                              <div className="bg-red-300 bottom-0 left-0 top-3 h-0.5 w-[80px] rounded-bl-sm rounded-tr-sm py-0.5 sm:w-[60px] lg:w-[100px]"></div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </motion.div>
                  );
                })}
              </motion.div>
            </AnimatePresence>
          )}
        </div>
      )}
    </>
  );
}

export default Leaderboard;
